import React from "react";
// import { decodeToken } from "react-jwt";
import { Navigate } from "react-router-dom";
import { useLoginContext } from "../context/loginContext";
import { role } from "../utils/globalVariables";
import { safetyDecodeJWT } from "../utils/globalFunctions";
import LoginIndex from "../pages/login/LoginIndex";

const LoginRoute = () => {

    const { loginContext } = useLoginContext();

    try {
        if (safetyDecodeJWT(loginContext, 'syswebapp', 'syswebappsl') === role.dosen.subLevel.dosen.slugSubLevel) {
            return <Navigate
                to={`/${role.dosen.subLevel.dosen.slugLink}/my-drive`}
                replace={true}
            />
        }

        // else if (safetyDecodeJWT(loginContext, 'syswebapp', 'syswebappsl') === subLevelSlugSPV) {
        //     return <Navigate to={`/${linkSlugSPV}/${mnSPVPermintaanBarang}`} replace={true} />
        // } else if (safetyDecodeJWT(loginContext, 'syswebapp', 'syswebappsl') === subLevelSlugAS1) {
        //     return <Navigate to={`/${linkSlugAS1}/${mnAS1PermintaanBarang}`} replace={true} />
        // } else if (safetyDecodeJWT(loginContext, 'syswebapp', 'syswebappsl') === subLevelSlugAS2) {
        //     return <Navigate to={`/${linkSlugAS2}/${mnAS2PermintaanBarang}`} replace={true} />
        // }

        else {
            return <LoginIndex />;
        }
    } catch (error) {
        return <LoginIndex />;
    }
}

export default LoginRoute;