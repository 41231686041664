import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MantineProvider, ColorSchemeProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { useLocalStorage } from '@mantine/hooks';

import { useLoginContext } from "./context/loginContext";
import { useLoginContextSU } from "./context/loginContextSU";

import { utils, menuList, BE_API_AUTH, BE_API_SU_ONE } from "./utils/globalVariables";

import MQT from "./components/dev/MQT";
import AppShellComponent from './layout/AppShellComponent';
import MainComponent from './pages/MainComponent';

import LoginRoute from "./routes/LoginRoute";
import DosenRoute from "./routes/DosenRoute";

const App = () => {

  const [colorSkema, setColorScheme] = useLocalStorage({
    key: utils.localStorageName.theme,
    defaultValue: utils.theme.light.slug,
    getInitialValueInEffect: false,
  });

  return (
    <ColorSchemeProvider
      colorScheme={colorSkema}
      toggleColorScheme={(value) => {
        setColorScheme(value || (colorSkema === utils.theme.dark.slug ? utils.theme.light.slug : utils.theme.dark.slug));
      }}
    >
      <MantineProvider
        theme={{
          colorScheme: colorSkema,
          colors: {
            // Add your color
            // tosca: ['#e8faf8', '#d2f5f1', '#bbefeb', '#a4eae4', '#8ee5dd', '#77e0d6', '#61dbcf', '#4ad6c8', '#33d0c2', '#2bbdaf'],

            // Replace default theme color
            // dark.6 from #25262B changes to #212226
            // dark: ['#C1C2C5', '#A6A7AB', '#909296', '#5C5F66', '#373A40', '#2C2E33', '#212226', '#1A1B1E', '#141517', '#101113'],
          },

          activeStyles: { transform: 'none' },

          components: {
            InputWrapper: {
              styles: () => ({
                label: {
                  marginBottom: '.5rem'
                },
                description: {
                  marginBottom: '.25rem'
                }
              }),
            },
            Notification: {
              styles: (tema) => ({
                root: {
                  border: tema.colorScheme === utils.theme.light.slug ? `1px solid ${tema.colors.gray[1]}` : `1px solid ${tema.colors.gray[9]}`
                }
              })
            }
          },
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Notifications
          position="bottom-left"
        // containerWidth={325}
        />

        <MQT
          stateIsRight={false}
          stateIsBottom={false}
        />

        <BrowserRouter>

          <Routes>
            <Route path='/' element={<LoginRoute />} />

            <Route
              path='ds/my-drive'
              element={<DosenRoute stateComponent={<MainComponent />} />}
            />

          </Routes>

        </BrowserRouter>

      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
