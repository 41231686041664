import React from 'react';
import { useMediaQuery } from '@mantine/hooks';

import { Header, ActionIcon, MediaQuery, useMantineTheme, Group, Box, ScrollArea, Avatar, Menu, Paper, Divider, Text, Switch, useMantineColorScheme } from '@mantine/core';
import { IconSettings, IconUser, IconAlignLeft, IconSearch, IconPhoto, IconMessageCircle, IconTrash, IconArrowsLeftRight, IconPlus, IconFolderPlus, IconFolder, IconFile, IconFolders, IconUsers, IconClockHour4, IconStar, IconCloud, IconAdjustmentsHorizontal, IconX, IconMoonStars, IconSunHigh } from '@tabler/icons-react';

import { useLoginContext } from "../context/loginContext";

import { utils, brand } from '../utils/globalVariables';
import { safetyDecodeJWT, getPropInSubLevelJWT } from '../utils/globalFunctions';

import ModalTakeAction from "../components/general/ModalTakeAction";

import BrandComponent from './BrandComponent';
import MenuDrawer from './MenuDrawer';
import SearchHeaderComponent from './SearchHeaderComponent';

const HeaderComponent = () => {

    const theme = useMantineTheme();
    const { loginContext, handleSetLoginContext } = useLoginContext();
    const { toggleColorScheme } = useMantineColorScheme();

    const matchesMinSM = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
    const matchesMinLG = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);
    const matchesMinXL = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);

    const [data, setData] = React.useState({
        signOut: {
            modal: false
        }
    })

    return (
        <React.Fragment>
            <Header
                height={{
                    base: 60,
                    md: 70
                }}

                sx={(tema) => ({
                    borderWidth: 0,
                    backgroundColor: tema.colorScheme === 'light' ? utils.theme.light.mainColor : tema.colors.dark[8]
                })}
            >
                <Group
                    spacing={0}
                    noWrap
                    align='center'
                    sx={{
                        height: '100%'
                    }}
                >
                    <MenuDrawer />

                    <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                        <Box
                            pl='md'
                            pr='md'
                            w={{
                                sm: utils.navbar.width1,
                                xl: utils.navbar.width2,
                            }}
                            sx={() => ({
                                minWidth: matchesMinSM ? (matchesMinXL ? utils.navbar.width2 : utils.navbar.width1) : 0,
                            })}
                        >
                            <BrandComponent />
                        </Box>
                    </MediaQuery>

                    <Box
                        sx={{
                            width: matchesMinLG ? '60%' : '100%',
                        }}
                    >
                        <SearchHeaderComponent />
                    </Box>

                    <Group
                        noWrap
                        spacing='sm'
                        ml='auto'
                        pl='md'
                        mr='md'
                    >
                        <Menu
                            shadow="md"
                            width={250}
                        >
                            <Menu.Target>
                                <ActionIcon
                                    variant='subtle'
                                    radius='xl'
                                    size='lg'
                                    sx={{
                                        border: 0,
                                        color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                        '&:hover': {
                                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.blue[0] : theme.colors.dark[6],
                                        }
                                    }}
                                    title={'Settings'}
                                >
                                    <IconSettings
                                        size={22}
                                        strokeWidth={1.5}
                                    />
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Label>
                                    <Text
                                        size='xs'
                                        fw='normal'
                                        sx={(tema) => ({
                                            color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.dark[1]
                                        })}
                                    >
                                        Color Mode
                                    </Text>

                                    <Switch
                                        label={theme.colorScheme === 'dark' ? 'Light' : 'Dark'}
                                        color={theme.colorScheme === 'dark' ? 'gray' : 'dark'}
                                        onLabel={<IconSunHigh size="1rem" stroke={2.5} color={theme.colors.yellow[4]} />}
                                        offLabel={<IconMoonStars size="1rem" stroke={2.5} color={theme.colors.blue[6]} />}

                                        checked={theme.colorScheme === 'dark' ? true : false}
                                        onChange={() => toggleColorScheme()}

                                        size="md"
                                        mt='sm'
                                        mb='.25rem'

                                        sx={{
                                            userSelect: 'none',
                                        }}

                                        styles={() => ({
                                            label: {
                                                cursor: 'pointer',
                                                fontWeight: 'normal'
                                            },
                                            track: {
                                                cursor: 'pointer'
                                            }
                                        })}
                                    />
                                </Menu.Label>
                            </Menu.Dropdown>
                        </Menu>

                        <Menu
                            shadow="md"
                            width={250}
                        >
                            <Menu.Target>
                                <ActionIcon
                                    variant='subtle'
                                    radius='xl'
                                    size='lg'
                                    sx={{
                                        border: 0,
                                        color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                        '&:hover': {
                                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.blue[0] : theme.colors.dark[6],
                                        }
                                    }}
                                    title={'Account'}
                                >
                                    <IconUser
                                        size={22}
                                        strokeWidth={1.5}
                                    />
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Label>
                                    <Text
                                        sx={(tema) => ({
                                            color: tema.colorScheme === 'light' ? tema.colors.dark[9] : tema.colors.dark[0]
                                        })}
                                    >
                                        <Text
                                            size='sm'
                                        >
                                            {safetyDecodeJWT(loginContext, 'syswebapp', 'syswebappn')}
                                        </Text>

                                        <Text>
                                            {getPropInSubLevelJWT(loginContext, 'caption', '')}
                                        </Text>
                                    </Text>
                                </Menu.Label>

                                <Menu.Divider />

                                <Menu.Item
                                // onClick={() => {
                                //     navigate(`/${getPropInSubLevelRole(safetyDecodeJWT(loginContext, 'syswebapp', 'syswebappsl'), 'slugLink', '')}/${stateMenuList.account.slug}/${stateMenuList.account.subMenu.one.flag}/${stateMenuList.account.subMenu.one.tabs.one.flag}`, { replace: true });
                                // }}
                                >
                                    Account Settings
                                </Menu.Item>

                                <Menu.Item
                                    onClick={() => setData(d => ({ ...d, signOut: { ...d.signOut, modal: true } }))}
                                >
                                    Sign Out
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                </Group>
            </Header>

            <ModalTakeAction
                stateShow={data.signOut.modal}
                fnHide={() => setData(d => ({ ...d, signOut: { ...d.signOut, modal: false } }))}
                stateTitle='Confirmation'
                stateButtonActionCaption='Sign Out'
                fnButtonAction={() => handleSetLoginContext(null)}
                stateButtonActionLoading={false}
                stateButtonActionPx='xs'
            >
                Sign Out ?
            </ModalTakeAction>
        </React.Fragment>
    );
}

export default HeaderComponent;