import React from "react";
import { Modal, Group, Button, ScrollArea, Box } from '@mantine/core';

const ModalTakeAction = ({
    children,
    stateShow,
    fnHide,
    stateHideOnClickOutSide = true,
    stateTitle = '',
    stateSize = 'md',
    stateScrollArea = true,

    stateButtonCloseCaption = 'Close',
    stateButtonClosePx = 'md',
    stateButtonCloseSize = 'xs',
    stateButtonCloseVariant = 'light',
    stateButtonCloseColor = '',

    stateButtonActionCaption = 'Submit',
    stateButtonActionPx = 'md',
    stateButtonActionSize = 'xs',
    stateButtonActionVariant = 'filled',
    stateButtonActionColor = '',
    stateButtonActionLoading = false,
    stateButtonActionDisabled = false,
    stateOverlayZIndex = null,
    fnButtonAction,
}) => {

    const sxZIndex = stateOverlayZIndex === null ? {} : { zIndex: stateOverlayZIndex }

    return (
        <Modal.Root
            opened={stateShow}
            onClose={fnHide}
            closeOnClickOutside={stateHideOnClickOutSide}
            size={stateSize}
            centered

            styles={() => ({
                overlay: {
                    // ...{
                    //     opacity: 0.9,
                    //     blur: 6,
                    //     zIndex: 201
                    // },
                    ...sxZIndex
                },
            })}
        >
            <Modal.Overlay />

            <Modal.Content
                scrollAreaComponent={stateScrollArea ? ScrollArea.Autosize : null}

            // info : sayangnya, code ini menghasilkan bug, yaitu: component parent rerender (kasus: jika parent input sesuatu, setiap satu karakter, parent langsung rerender. Proses input jd ga smooth)
            // scrollAreaComponent={stateScrollArea ?
            //     (props) => (
            //         <ScrollArea.Autosize type="always" {...props}>
            //             {props.children}
            //         </ScrollArea.Autosize>
            //     )
            //     :
            //     null
            // }
            >
                <Modal.Header>
                    <Modal.Title sx={{ fontWeight: '500' }}>{stateTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {children}
                </Modal.Body>

                <Modal.Header sx={{ bottom: 0 }}>
                    <Box sx={{ width: '100%' }}>
                        <Group position='right'>
                            <Button
                                color={stateButtonCloseColor}
                                onClick={fnHide}
                                size={stateButtonCloseSize}
                                variant={stateButtonCloseVariant}
                                px={stateButtonClosePx}
                            >
                                {stateButtonCloseCaption}
                            </Button>

                            <Button
                                color={stateButtonActionColor}
                                size={stateButtonActionSize}
                                variant={stateButtonActionVariant}
                                disabled={stateButtonActionDisabled}
                                loaderPosition="right"
                                px={stateButtonActionPx}
                                loading={stateButtonActionLoading}
                                onClick={fnButtonAction}
                            >
                                {stateButtonActionCaption}
                            </Button>
                        </Group>
                    </Box>
                </Modal.Header>
            </Modal.Content>
        </Modal.Root>
    )
}

export default React.memo(ModalTakeAction);