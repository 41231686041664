import React from "react";
import { Group, useMantineTheme, Switch, Box, useMantineColorScheme, createStyles } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import { utils } from "../../utils/globalVariables";

const useStyles = createStyles((theme) => ({
    mediaQueryIndicator: {
        [theme.fn.largerThan('xl')]: {
            '&:after': {
                content: `'XXL'`
            }
        },
        [theme.fn.smallerThan('xl')]: {
            '&:after': {
                content: `'XL'`
            }
        },
        [theme.fn.smallerThan('lg')]: {
            '&:after': {
                content: `'LG'`
            }
        },
        [theme.fn.smallerThan('md')]: {
            '&:after': {
                content: `'MD'`
            }
        },
        [theme.fn.smallerThan('sm')]: {
            '&:after': {
                content: `'SM'`
            }
        },
        [theme.fn.smallerThan('xs')]: {
            '&:after': {
                content: `'XS'`
            }
        },
    },
}));

const MQT = ({ stateIsRight = true, stateIsBottom = true }) => {

    const theme = useMantineTheme();
    const { classes } = useStyles();
    const { toggleColorScheme } = useMantineColorScheme();
    const { width, height } = useViewportSize();

    const isRightLeft = stateIsRight ? { right: '.25rem' } : { left: '.25rem' };
    const isTopBottom = stateIsBottom ? { bottom: '.25rem' } : { top: '.25rem' };

    return (
        <Group
            noWrap
            spacing='sm'
            sx={{
                ...{
                    position: 'fixed',
                    // bottom: '.25rem',
                    // right: '.25rem',
                    backgroundColor: 'rgba(0, 0, 0, .75)',
                    paddingLeft: '.5rem',
                    paddingRight: '.5rem',
                    paddingTop: '.25rem',
                    paddingBottom: '.25rem',
                    borderRadius: '4px',
                    zIndex: 99999,
                    color: '#ccc',
                    display: utils.showMqt ? 'flex' : 'none'
                },
                ...isRightLeft,
                ...isTopBottom
            }}
        >
            <Switch
                label="Dark"
                checked={theme.colorScheme === 'dark' ? true : false}
                onChange={() => toggleColorScheme()}

                size="xs"
                pr='sm'
                className="mqt-switch-theme"

                style={{
                    userSelect: 'none',
                    display: 'none',
                    borderRight: '1px solid #ccc'
                }}

                styles={() => ({
                    label: {
                        cursor: 'pointer',
                        color: '#ccc'
                    },
                    track: {
                        cursor: 'pointer'
                    }
                })}
            />

            <Group
                noWrap
                spacing='xs'
                sx={{ fontSize: '12px', cursor: 'pointer', userSelect: 'none', }}
                onClick={() => {
                    const elMQTSwitchTheme = document.querySelector('.mqt-switch-theme');

                    if (elMQTSwitchTheme.style.display === 'block') {
                        elMQTSwitchTheme.style.setProperty('display', 'none');
                    } else {
                        elMQTSwitchTheme.style.setProperty('display', 'block');
                    }
                }}
            >
                <Box
                    className={classes.mediaQueryIndicator}
                />

                <Box>
                    {width} x {height}
                </Box>
            </Group>
        </Group>
    )
}

export default MQT;