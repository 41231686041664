import React from "react";

import { utils } from "../utils/globalVariables";
import { getLocalStorage, setLocalStorage } from "../utils/globalFunctions";

const LoginContextSU = React.createContext({})

export const LoginContextSUProvider = ({ children }) => {

    const getDefaultData = () => {
        const defaultData = getLocalStorage(utils.localStorageName.loginSU);
        const setData = defaultData ? defaultData : null;
        return setData ? JSON.parse(setData) : null;
    }

    const [loginContextSU, setLoginContextSU] = React.useState(getDefaultData());

    const handleSetLoginContextSU = (param) => {
        setLocalStorage(utils.localStorageName.loginSU, param ? JSON.stringify(param) : null);

        setLoginContextSU(param);
    }

    return (
        <LoginContextSU.Provider value={{ loginContextSU, setLoginContextSU, handleSetLoginContextSU }}>
            {children}
        </LoginContextSU.Provider>
    )
};

export const useLoginContextSU = () => React.useContext(LoginContextSU);