import React from "react";
import { useLocalStorage } from '@mantine/hooks';

import { utils } from "../utils/globalVariables";

const DisplayModeContext = React.createContext({})

export const DisplayModeContextProvider = ({ children }) => {

    const [displayMode, setDisplayMode] = useLocalStorage({
        key: utils.localStorageName.displayMode,
        defaultValue: utils.displayMode.card.slug,
        getInitialValueInEffect: false
    });

    const handleSetDisplayModeContext = (param) => {
        setDisplayMode(param);
    }

    return (
        <DisplayModeContext.Provider value={{ displayMode, handleSetDisplayModeContext }}>
            {children}
        </DisplayModeContext.Provider>
    )
};

export const useDisplayModeContext = () => React.useContext(DisplayModeContext);