import React from "react";
import Lottie from "lottie-react";
import { Box, Center, Text } from '@mantine/core';

import noDataLottie from "../../assets/lottie/noDataLottie.json";

const NoData = () => {
    return (
        <Box my='xl'>
            <Center>
                <Lottie
                    animationData={noDataLottie}
                    style={{
                        width: '10rem'
                    }}
                />
            </Center>

            <Center mt='md'>
                <Text size='md' color='dimmed'>No Data.</Text>
            </Center>
        </Box>
    )
}

export default NoData;