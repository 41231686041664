import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMantineTheme, NavLink, Box } from '@mantine/core';
import { IconTrash, IconFolders, IconUsers, IconClockHour4, IconStar, IconCloud } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';

// import { defaultTab } from "../utils/globalFunctions";

const MenuList = () => {

    const navigate = useNavigate();
    const { subMenu } = useParams();
    const theme = useMantineTheme();
    const matchesMinSM = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

    return (
        <Box>
            <NavLink
                label='My Drive'
                icon={<IconFolders size="1rem" stroke={1.5} />}
                active={true}
                mt='0'
                mb='.25rem'
                // mb={`${matchesMinSM ? '.25rem' : 'xs'}`}
                // active={stateMenuList[keyHeader].subMenu[keySubmenu].flag === subMenu}

                styles={(tema) => ({
                    root: {
                        '&[data-active]': {
                            backgroundColor: tema.colorScheme === 'light' ? '#C2E7FF' : tema.colors.dark[5],
                            color: tema.colorScheme === 'light' ? tema.colors.gray[9] : tema.colors.gray[0]
                        }
                    }
                })}

                sx={(tema) => ({
                    borderRadius: tema.radius.sm,
                    backgroundColor: tema.colorScheme === 'light' ? tema.colors.grape[1] : tema.colors.dark[5],
                    // borderRadius: tema.radius.xl
                })}

                onClick={() => {
                    // fnClickMenu();
                    // navigate(`/ow/${stateMenuList[keyHeader].slug}/${stateMenuList[keyHeader].subMenu[keySubmenu].flag}/${defaultTab(stateMenuList[keyHeader].subMenu[keySubmenu])}`, { replace: true })
                }}
            />

            <NavLink
                label='Shared with me'
                icon={<IconUsers size="1rem" stroke={1.5} />}
                active={false}
                mb='.25rem'

                styles={(tema) => ({
                    root: {
                        '&[data-active]': {
                            backgroundColor: tema.colorScheme === 'light' ? '#C2E7FF' : tema.colors.dark[5],
                            color: tema.colorScheme === 'light' ? tema.colors.gray[9] : tema.colors.gray[0]
                        }
                    }
                })}

                sx={(tema) => ({
                    borderRadius: tema.radius.sm,
                    // backgroundColor: tema.colorScheme === 'light' ? tema.colors.grape[1] : tema.colors.dark[5],
                    // borderRadius: tema.radius.xl
                })}
            />

            <NavLink
                label='Recent'
                icon={<IconClockHour4 size="1rem" stroke={1.5} />}
                active={false}
                mb='.25rem'

                styles={(tema) => ({
                    root: {
                        '&[data-active]': {
                            backgroundColor: tema.colorScheme === 'light' ? '#C2E7FF' : tema.colors.dark[5],
                            color: tema.colorScheme === 'light' ? tema.colors.gray[9] : tema.colors.gray[0]
                        }
                    }
                })}

                sx={(tema) => ({
                    borderRadius: tema.radius.sm,
                    // backgroundColor: tema.colorScheme === 'light' ? tema.colors.grape[1] : tema.colors.dark[5],
                    // borderRadius: tema.radius.xl
                })}
            />

            <NavLink
                label='Starred'
                icon={<IconStar size="1rem" stroke={1.5} />}
                active={false}
                mb='1rem'

                styles={(tema) => ({
                    root: {
                        '&[data-active]': {
                            backgroundColor: tema.colorScheme === 'light' ? '#C2E7FF' : tema.colors.dark[5],
                            color: tema.colorScheme === 'light' ? tema.colors.gray[9] : tema.colors.gray[0]
                        }
                    }
                })}

                sx={(tema) => ({
                    borderRadius: tema.radius.sm,
                    // backgroundColor: tema.colorScheme === 'light' ? tema.colors.grape[1] : tema.colors.dark[5],
                    // borderRadius: tema.radius.xl
                })}
            />

            <NavLink
                label='Trash'
                icon={<IconTrash size="1rem" stroke={1.5} />}
                active={false}
                mb='.25rem'

                styles={(tema) => ({
                    root: {
                        '&[data-active]': {
                            backgroundColor: tema.colorScheme === 'light' ? '#C2E7FF' : tema.colors.dark[5],
                            color: tema.colorScheme === 'light' ? tema.colors.gray[9] : tema.colors.gray[0]
                        }
                    }
                })}

                sx={(tema) => ({
                    borderRadius: tema.radius.sm,
                    // backgroundColor: tema.colorScheme === 'light' ? tema.colors.grape[1] : tema.colors.dark[5],
                    // borderRadius: tema.radius.xl
                })}
            />

            <NavLink
                label='512MB used'
                icon={<IconCloud size="1rem" stroke={1.5} />}
                active={false}
                mb='.25rem'

                styles={(tema) => ({
                    root: {
                        '&[data-active]': {
                            backgroundColor: tema.colorScheme === 'light' ? '#C2E7FF' : tema.colors.dark[5],
                            color: tema.colorScheme === 'light' ? tema.colors.gray[9] : tema.colors.gray[0]
                        }
                    }
                })}

                sx={(tema) => ({
                    borderRadius: tema.radius.sm,
                    // backgroundColor: tema.colorScheme === 'light' ? tema.colors.grape[1] : tema.colors.dark[5],
                    // borderRadius: tema.radius.xl
                })}
            />
        </Box>
    )
}

export default MenuList;