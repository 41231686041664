import React from "react";
import axios from "axios";
import { Button, ActionIcon, Text, useMantineTheme, Group, Box, ScrollArea, Dialog, Collapse, Progress, Tooltip, List } from '@mantine/core';
import { IconInfoCircle, IconDots, IconChevronRight, IconChevronDown, IconChevronUp, IconFile, IconFolder, IconArticle, IconUserCircle, IconX, IconUserPlus, IconDownload, IconFolderShare, IconTrash, IconLink, IconDotsVertical, IconPencil, IconFiles, IconStar, IconCaretDown, IconFolderFilled, IconFolderPlus, IconCheck, IconAlertCircle } from '@tabler/icons-react';

import { notifications } from '@mantine/notifications';
import { Dropzone } from '@mantine/dropzone';

import { useLoginContext } from "../../context/loginContext";

import { utils, BE_API_ONE } from "../../utils/globalVariables";

import ModalResponse from "../../components/general/ModalResponse";
import ModalTakeAction from "../../components/general/ModalTakeAction";
import ModalWrapperWithFooterCloseButton from "../../components/general/ModalWrapperWithFooterCloseButton";

const FileSelectorComponent = ({
    stateRef,
    stateData,
    fnSuccessUpload
}) => {

    const mountedRef = React.useRef(true);
    const { loginContext, handleSetLoginContext } = useLoginContext();
    const theme = useMantineTheme();

    const notificationId = React.useRef({
        errorUpload: 'fileSelectorComponent-1',
    })

    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [filesToUploadInfo, setFilesToUploadInfo] = React.useState([]);

    const [data, setData] = React.useState({
        modalResponse: {
            show: false,
            type: utils.modalResponse.warning,
            hideOnClickOutSide: false,
            content: ''
        },

        uploadInfoContainer: false,
        uploadInfoBody: true,

        cancelAllItemsConfirmation: false,
        cancelAllItemsSubmitLoading: false,

        errorWhileTryingToUpload: {
            component: <div />,
            modal: false
        }
    })

    React.useEffect(() => {
        return () => {
            // mountedRef.current = false;
        }
    }, [])

    const handleFileChange = (e) => {
        const newFiles = e.map(item => ({
            ...item,
            name: item.name,
            progress: 0,
            status: 'onProgress',
            cancelToken: null
        }));

        // setData(d => ({ ...d, uploadInfoContainer: true, uploadInfoBody: true }));

        setFilesToUpload((prev) => [...e, ...prev]);
        setFilesToUploadInfo((prev) => [...newFiles, ...prev]);

        handleUploadClick([...e, ...filesToUpload], [...newFiles, ...filesToUploadInfo]);
    };

    const uploadFile = async (file, index) => {
        setData(d => ({
            ...d,
            uploadInfoContainer: true,
            uploadInfoBody: true
        }));

        const formData = new FormData();
        formData.append('json_token', loginContext && loginContext.hasOwnProperty('syswebapp') ? loginContext.syswebapp : null);
        formData.append('parent_folder', JSON.stringify(stateData.parentFolderArray));
        formData.append(`item`, file);

        const source = axios.CancelToken.source();

        setFilesToUploadInfo((prev) => {
            const newArray = [...prev];
            newArray[index] = {
                ...newArray[index],
                ...{
                    cancelToken: source,
                }
            };
            return newArray;
        });

        try {
            const response = await axios.post(
                BE_API_ONE + '/upload_files',
                formData,
                {
                    headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
                    cancelToken: source.token,
                    onUploadProgress: (progressEvent) => {
                        const newProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

                        setFilesToUploadInfo((prev) => {
                            const newArray = [...prev];
                            newArray[index] = {
                                ...newArray[index],
                                ...{
                                    progress: newProgress,
                                    // status: progressEvent.loaded === progressEvent.total ? 'finish' : 'onProgress'
                                }
                            };
                            return newArray;
                        });
                    },
                });

            // console.log('respo', response);

            if (response.data.flag_status === 'isset') {
                fnSuccessUpload();

                setFilesToUploadInfo((prev) => {
                    const newArray = [...prev];
                    newArray[index] = {
                        ...newArray[index],
                        ...{
                            status: 'finish',
                            name: response.data.file_name
                        }
                    };
                    return newArray;
                });
            } else if (response.data.flag_status === 'no-parent-folder') {
                setFilesToUploadInfo((prev) => {
                    const newArray = [...prev];
                    newArray[index] = {
                        ...newArray[index],
                        ...{
                            status: 'error',
                            progress: 0
                        }
                    };
                    return newArray;
                });

                setData(d => ({
                    ...d,
                    modalResponse: {
                        ...d.modalResponse,
                        type: utils.modalResponse.warning,
                        hideOnClickOutSide: true,
                        content: 'Parent folder not found.',
                        show: true
                    }
                }))
            } else if (response.data.flag_status === 'invalid-json-token') {
                handleSetLoginContext(null);
            } else if (response.data.flag_status === 'user-inactive') {
                handleSetLoginContext(null);
            } else if (response.data.flag_status === 'error-upload') {
                setFilesToUploadInfo((prev) => {
                    const newArray = [...prev];
                    newArray[index] = {
                        ...newArray[index],
                        ...{
                            status: 'error',
                            progress: 0
                        }
                    };
                    return newArray;
                });

                setData(d => ({
                    ...d,
                    modalResponse: {
                        ...d.modalResponse,
                        type: utils.modalResponse.warning,
                        hideOnClickOutSide: true,
                        content: 'Error : ' + response.data.pesan,
                        show: true
                    }
                }))
            } else {
                setFilesToUploadInfo((prev) => {
                    const newArray = [...prev];
                    newArray[index] = {
                        ...newArray[index],
                        ...{
                            status: 'error',
                            progress: 0
                        }
                    };
                    return newArray;
                });
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                setFilesToUploadInfo((prev) => {
                    const newArray = [...prev];
                    newArray[index] = {
                        ...newArray[index],
                        ...{
                            status: 'cancelled',
                        }
                    };
                    return newArray;
                });
            } else {
                notifications.show({
                    id: notificationId.current.errorUpload,
                    title: utils.notification.warning.caption,
                    message: error,
                    color: utils.notification.warning.color,
                    icon: utils.notification.warning.icon,
                    withBorder: true,
                    autoClose: false,
                })
            }
        }
    };

    const cancelUpload = (index) => {
        try {
            const file = filesToUploadInfo[index];
            if (file && file.cancelToken) {
                file.cancelToken.cancel('File upload canceled');
                // Lakukan hal lain yang diperlukan setelah pembatalan pengungahan
            }
        } catch (error) {
            //   
        }
    };

    const handleUploadClick = (stateFiles, stateFilesInfo) => {
        stateFiles.forEach((file, index) => {
            if (stateFilesInfo[index].status === 'onProgress') {
                uploadFile(file, index);
            }
        });
    };

    /*
    info : Contoh struktur error
    const err = [
        { errors: [{ code: '1', message: 'a' }, { code: '2', message: 'b' },], file: { path: 'aaaa', name: 'asdasd' } },
        { errors: [{ code: '3', message: 'c' }, { code: '4', message: 'd' },], file: { path: 'bbbb', name: 'hdfhgf' } },
    ]
    */
    const fnOnChangeFileError = (err) => {
        setData(d => ({
            ...d,
            errorWhileTryingToUpload: {
                ...d.errorWhileTryingToUpload,
                component: <Box>
                    {Array.isArray(err) && (err.length > 0) && Object.keys(err).map((keyErr, indexErr) => {
                        return (
                            <Box key={keyErr} mb='sm'>
                                <Text
                                    sx={{
                                        wordBreak: 'break-all'
                                    }}
                                >
                                    {err[keyErr].file.name}
                                </Text>

                                {Array.isArray(err[keyErr].errors) &&
                                    <List type="ordered" size="sm" withPadding>
                                        {Object.keys(err[keyErr].errors).map((keyE, indexE) => {
                                            return (
                                                <List.Item key={keyE}>
                                                    <Text
                                                        color="dimmed"
                                                    >
                                                        {err[keyErr].errors[keyE].message}
                                                    </Text>
                                                </List.Item>
                                            )
                                        })}
                                    </List>
                                }
                            </Box>
                        )
                    })}
                </Box>,
                modal: true
            }
        }))
    }

    const cekDuplicateName = (arr) => {
        const yu = arr.map(item => item.name);
        console.log(yu);

        // do some stuff here

        handleFileChange(arr)
    }

    const cancelAllItems = () => {
        if (Array.isArray(filesToUploadInfo)) {
            const isSet = filesToUploadInfo.filter(item => item.status === 'onProgress');

            if (Array.isArray(isSet) && (isSet.length > 0)) {
                try {
                    for (let index = 0; index < filesToUploadInfo.length; index++) {
                        const file = filesToUploadInfo[index];
                        if (file && file.cancelToken) {
                            file.cancelToken.cancel('File upload canceled');
                            // Lakukan hal lain yang diperlukan setelah pembatalan pengungahan
                        }
                    }

                    setFilesToUpload([]);
                    setFilesToUploadInfo([]);

                    setData(d => ({
                        ...d,
                        cancelAllItemsConfirmation: false,
                        uploadInfoContainer: false
                    }))
                } catch (error) {
                    //   
                }
            }
        } else {
            setFilesToUpload([]);
            setFilesToUploadInfo([]);

            setData(d => ({
                ...d,
                cancelAllItemsConfirmation: false,
                uploadInfoContainer: false
            }))
        }
    }

    const closeStatusBar = () => {
        if (Array.isArray(filesToUploadInfo)) {
            const isSet = filesToUploadInfo.filter(item => item.status === 'onProgress');

            if (Array.isArray(isSet) && (isSet.length > 0)) {
                setData(d => ({
                    ...d,
                    cancelAllItemsConfirmation: true
                }))
            } else {
                setFilesToUpload([]);
                setFilesToUploadInfo([]);

                setData(d => ({
                    ...d,
                    cancelAllItemsConfirmation: false,
                    uploadInfoContainer: false
                }))
            }
        } else {
            setFilesToUpload([]);
            setFilesToUploadInfo([]);

            setData(d => ({
                ...d,
                cancelAllItemsConfirmation: false,
                uploadInfoContainer: false
            }))
        }
    }

    return (
        <React.Fragment>
            <Dropzone
                multiple
                openRef={stateRef}
                onDrop={(arr) => {
                    cekDuplicateName(arr);
                }}

                onReject={fnOnChangeFileError}

                sx={(tema) => ({
                    backgroundColor: 'transparent',
                    display: 'none',
                    borderWidth: '0',
                })}
            />

            <Dialog
                opened={data.uploadInfoContainer}
                onClose={() => setData(d => ({
                    ...d,
                    uploadInfoContainer: false,
                }))}
                withCloseButton={false}
                transition="slide-up"
                transitionDuration={300}
                transitionTimingFunction="ease"
                size="lg"
                radius="md"
                shadow="sm"
                withBorder
                p='0'
                position={{ bottom: 0, right: '.75rem' }}

                styles={(tema) => ({
                    root: {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    }
                })}
            >
                <Group
                    align="center"
                    p='xs'
                    sx={(tema) => ({
                        backgroundColor: tema.colorScheme === 'light' ? tema.colors.gray[0] : tema.colors.dark[6],
                        borderTopLeftRadius: tema.radius.md,
                        borderTopRightRadius: tema.radius.md,
                    })}
                >
                    <Text size="sm" weight={500}>
                        {filesToUploadInfo.filter(item => item.status === 'finish').length} Files Uploaded
                    </Text>

                    <Group ml='auto' spacing='xs'>
                        <ActionIcon
                            variant='subtle'
                            radius='xl'
                            size='md'
                            sx={{
                                border: 0,
                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                '&:hover': {
                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[5],
                                }
                            }}
                            title={'Show'}
                            onClick={() => setData(d => ({ ...d, uploadInfoBody: !d.uploadInfoBody }))}
                        >
                            {data.uploadInfoBody ?
                                <IconChevronDown
                                    size={20}
                                    strokeWidth={2}
                                />
                                :
                                <IconChevronUp
                                    size={20}
                                    strokeWidth={2}
                                />
                            }
                        </ActionIcon>

                        <ActionIcon
                            variant='subtle'
                            radius='xl'
                            size='md'
                            sx={{
                                border: 0,
                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                '&:hover': {
                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[5],
                                }
                            }}
                            title={'Close'}
                            onClick={() => closeStatusBar()}
                        >
                            <IconX
                                size={16}
                                strokeWidth={2}
                            />
                        </ActionIcon>
                    </Group>
                </Group>

                <Collapse in={data.uploadInfoBody}>
                    <ScrollArea.Autosize
                        offsetScrollbars
                        px='sm'
                        pt='sm'
                        sx={{
                            backgroundColor: theme.colorScheme === 'light' ? '#fff' : theme.colors.dark[7],
                            maxHeight: 200,
                            width: '100%',
                        }}
                    >
                        {Array.isArray(filesToUploadInfo) && (filesToUploadInfo.length > 0) && Object.keys(filesToUploadInfo).map((keyL, indexL) => {

                            const onProgress = filesToUploadInfo[keyL].status === 'onProgress';
                            const cancelled = filesToUploadInfo[keyL].status === 'cancelled';
                            const error = filesToUploadInfo[keyL].status === 'error';

                            return (
                                <Box
                                    key={keyL}
                                    mb='1.35rem'
                                    sx={{
                                        width: '100%'
                                    }}
                                >
                                    <Group
                                        noWrap
                                        align="center"
                                        spacing='xs'
                                        mb='xs'
                                    >
                                        <Tooltip
                                            label={filesToUploadInfo[keyL].name}
                                            multiline
                                            openDelay={600}
                                            withArrow
                                            position="top"
                                            styles={(tema) => ({
                                                tooltip: {
                                                    wordBreak: 'break-all'
                                                }
                                            })}
                                        >
                                            <Text
                                                size='sm'
                                                lineClamp={1}
                                                // truncate
                                                sx={(tema) => ({
                                                    color: tema.colorScheme === 'light' ? tema.colors.gray[8] : tema.colors.dark[2],
                                                    wordBreak: 'break-all',
                                                })}
                                            >
                                                {filesToUploadInfo[keyL].name}
                                            </Text>
                                        </Tooltip>

                                        <ActionIcon
                                            variant='subtle'
                                            radius='xl'
                                            size='sm'
                                            ml='auto'
                                            sx={{
                                                border: 0,
                                                cursor: onProgress ? 'pointer' : 'default',

                                                color: theme.colorScheme === 'light' ? (onProgress ? theme.colors.gray[7] : '#fff') : (onProgress ? theme.colors.dark[1] : '#fff'),

                                                backgroundColor: theme.colorScheme === 'light' ? (onProgress ? theme.colors.gray[1] : ((cancelled || error) ? theme.colors.red[6] : theme.colors.green[6])) : (onProgress ? theme.colors.dark[5] : ((cancelled || error) ? theme.colors.red[8] : theme.colors.green[9])),

                                                '&:hover': {
                                                    color: theme.colorScheme === 'light' ? (onProgress ? theme.colors.blue[6] : '#fff') : (onProgress ? theme.colors.blue[1] : '#fff'),

                                                    backgroundColor: theme.colorScheme === 'light' ? (onProgress ? theme.colors.blue[1] : ((cancelled || error) ? theme.colors.red[6] : theme.colors.green[6])) : (onProgress ? theme.colors.blue[7] : ((cancelled || error) ? theme.colors.red[8] : theme.colors.green[9])),
                                                }
                                            }}
                                            title={'Action'}

                                            onClick={onProgress ?
                                                () => cancelUpload(Number(keyL))
                                                :
                                                () => { }
                                            }
                                        >
                                            {onProgress ?
                                                <IconX
                                                    size={16}
                                                    strokeWidth={2}
                                                />
                                                :
                                                (cancelled ?
                                                    <IconTrash
                                                        size={16}
                                                        strokeWidth={2}
                                                    />
                                                    :
                                                    (error ?
                                                        <IconAlertCircle
                                                            size={16}
                                                            strokeWidth={2}
                                                        />
                                                        :
                                                        <IconCheck
                                                            size={16}
                                                            strokeWidth={2}
                                                        />
                                                    )
                                                )
                                            }
                                        </ActionIcon>
                                    </Group>

                                    {onProgress &&
                                        <Progress value={filesToUploadInfo[keyL].progress} size='xs' radius="md" />
                                    }
                                </Box>
                            )
                        })}
                    </ScrollArea.Autosize>
                </Collapse>
            </Dialog>

            <ModalResponse
                stateShow={data.modalResponse.show}
                fnHide={() => setData(d => ({
                    ...d,
                    modalResponse: {
                        ...d.modalResponse,
                        show: false
                    }
                }))}

                stateHideOnClickOutSide={data.modalResponse.hideOnClickOutSide}
                stateType={data.modalResponse.type}
                stateContent={data.modalResponse.content}
            />

            <ModalTakeAction
                stateShow={data.cancelAllItemsConfirmation}
                fnHide={() => setData(d => ({
                    ...d,
                    cancelAllItemsConfirmation: false
                }))}

                stateHideOnClickOutSide={true}
                stateTitle={String('Confirmation').toUpperCase()}
                // stateOverlayZIndex={201}

                stateButtonClosePx='md'

                stateButtonActionCaption='Cancel all items & close'
                stateButtonActionPx='xs'
                stateButtonActionColor="blue"
                stateButtonActionLoading={data.cancelAllItemsSubmitLoading}
                fnButtonAction={cancelAllItems}
            >
                <Text size='sm' mt='sm'>
                    Cancel all ongoing uploads and close ?
                </Text>
            </ModalTakeAction>

            <ModalWrapperWithFooterCloseButton
                stateShow={data.errorWhileTryingToUpload.modal}
                // stateShow={true}
                fnHide={() => setData(d => ({ ...d, errorWhileTryingToUpload: { ...d.errorWhileTryingToUpload, modal: false } }))}
                stateHideOnClickOutSide={true}
                stateTitle={String('Error Information').toUpperCase()}
                stateSize='lg'
                // stateOverlayZIndex={201}
                stateWithCloseButton={true}
                stateScrollArea={true}

                stateButtonCloseCaption="Close"
                stateButtonCloseSize="xs"
                stateButtonClosePx="lg"
            >
                {data.errorWhileTryingToUpload.component}
            </ModalWrapperWithFooterCloseButton>
        </React.Fragment>
    )
}

export default React.memo(FileSelectorComponent);