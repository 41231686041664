import React from "react";
import axios from "axios";
import { AppShell, Button, Drawer, ActionIcon, Text, useMantineTheme, Group, Box, ScrollArea, Menu, Tabs } from '@mantine/core';
import { useMediaQuery, useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconInfoCircle, IconDots, IconChevronRight, IconChevronDown, IconFile, IconFolder, IconArticle, IconUserCircle, IconX, IconUserPlus, IconDownload, IconFolderShare, IconTrash, IconLink, IconDotsVertical, IconPencil, IconFiles, IconStar, IconFolderFilled, IconFolderPlus } from '@tabler/icons-react';
import JSZip from 'jszip';

import { motion, useAnimation } from "framer-motion";

import { useLoginContext } from "../context/loginContext";
import { useDisplayModeContext } from "../context/displayModeContext";
import { useOrderTypeContext } from "../context/orderTypeContext";
import { useOrderByContext } from "../context/orderByContext";
import { useOrderingItemContext } from "../context/orderingItemContext";

import { BE_API_ONE, utils } from '../utils/globalVariables';
import DisplayModeSelector from '../components/general/DisplayModeSelector';

import ModalResponse from "../components/general/ModalResponse";
import OrderingItemSelector from '../components/general/OrderingItemSelector';
import OrderTypeSelector from '../components/general/OrderTypeSelector';
import OrderBySelector from '../components/general/OrderBySelector';
import ItemOrderingTypeCaption from '../components/general/ItemOrderingTypeCaption';
import NoData from '../components/general/NoData';

import HeaderComponent from '../layout/HeaderComponent';
import NavBarComponent from '../layout/NavBarComponent';
import ItemsComponent from './ItemsComponent';

import FnNewFolder from '../functions/FnNewFolder';
import FileSelectorComponent from '../components/general/FileSelectorComponent';

import FnRenameFolder from "../functions/FnRenameFolder";

const DetailsDetails = () => {
    return (
        <Box>
            <Text pb='4rem'>
                Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </Text>

            <Text pb='4rem'>
                Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </Text>

            <Text pb='4rem'>
                Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </Text>

            <Text pb='4rem'>
                Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </Text>

            <Text pb='4rem'>
                Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </Text>

            <Text pb='4rem'>
                End Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </Text>
        </Box>
    )
}

const DetailsActivity = () => {
    return (
        <Box>
            <Text pb='4rem'>
                Activity.
            </Text>
        </Box>
    )
}

const OutsideComponentHandler = ({ children, handleRemoveSelected }) => {
    const wrapperRef = React.useRef(null);

    const handleClickOutside = React.useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            //
        } else {
            handleRemoveSelected();
        }
    }, [handleRemoveSelected]);

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    return <Box ref={wrapperRef}>{children}</Box>;
};

const MainComponent = () => {

    const mountedRef = React.useRef(true);
    const openRef = React.useRef(null);

    const theme = useMantineTheme();
    const { loginContext, handleSetLoginContext } = useLoginContext();
    const { displayMode } = useDisplayModeContext();

    const { orderType } = useOrderTypeContext();
    const { orderBy } = useOrderByContext();
    const { orderingItem } = useOrderingItemContext();

    const notificationId = React.useRef({
        errorGetData: 'MainComponent-1',
    })

    const matchesMinXS = useMediaQuery(`(min-width: ${theme.breakpoints.xs})`);
    const matchesMinSM = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);
    const matchesMinMD = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);
    const matchesMinLG = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);
    const matchesMinXL = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`);

    const matchesMaxMD = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

    const controlsComp1 = useAnimation();
    const controlsComp2 = useAnimation();

    const minHeightFilterUtilsBar = '42px';

    const [data, setData] = React.useState({
        innerHeight: window.innerHeight,
        data: [],
        dataCount: 0,
        dataFlag: 'idle',
        dataLoading: false,
        dataRefresh: new Date(),
        dataOffset: 0,
        // dataSortSelected: sortList.stockCategoryName,
        // dataSearchInput: '',
        // dataSearchInputLoading: false,

        details: {
            show: false,
            tabSelected: utils.details.tabDetails.value,

            drawer: false
        },
        items: {
            selected: []
        },
        folderParentId: [null],
        dataPath: [],

        newFolder: {
            show: false
        },

        renameFolderParent: {
            modal: '',
            id: '',
            name: ''
        },

        renameFolder: {
            modal: '',
            id: '',
            name: ''
        },

        modalResponse: {
            show: false,
            type: utils.modalResponse.warning,
            hideOnClickOutSide: false,
            content: ''
        }
    })

    const [debounced] = useDebouncedValue(data.innerHeight, 1000);

    const handleResize = () => {
        setData(d => ({ ...d, innerHeight: window.innerHeight }));
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    React.useEffect(() => {
        return () => {
            // mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        const formDT = new FormData();
        const formDTHeader = { 'Content-Type': 'application/json' }

        formDT.append('json_token', loginContext && loginContext.hasOwnProperty('syswebapp') ? loginContext.syswebapp : null);
        formDT.append('data_limit', utils.dataLimit);
        formDT.append('data_offset', data.dataOffset);
        formDT.append('order_by', orderBy);
        formDT.append('order_type', orderType);
        formDT.append('ordering_item', orderingItem);

        formDT.append('ordering_item_folder_on_top', utils.orderingItem.foldersOnTop.value);
        formDT.append('ordering_item_mixed_with_files', utils.orderingItem.mixedWithFiles.value);

        formDT.append('folder_id', JSON.stringify(data.folderParentId));

        formDT.append('flag_all', utils.flagAll);
        // formDT.append('filter_search', searchInputDebounced);
        formDT.append('filter_search', '');

        axios({
            method: 'post',
            url: BE_API_ONE + '/get_data',
            data: formDT,
            headers: formDTHeader,
        }).then((response) => {
            if (!mountedRef.current) return null;

            console.log('dt', response.data);

            setData(d => ({ ...d, dataLoading: false }));

            if (response.data.flag_status === 'isset') {
                setData(d => ({
                    ...d,
                    data: response.data.data,
                    dataCount: response.data.data_count,
                    dataFlag: response.data.data.length > 0 ? true : false,
                    dataPath: response.data.data_path,
                }));
            }

            if (response.data.flag_status === 'no-parent-folder') {
                setData(d => ({
                    ...d,
                    folderParentId: [...d.folderParentId.slice(0, -1)],
                    dataRefresh: new Date(),
                    modalResponse: {
                        ...d.modalResponse,
                        type: utils.modalResponse.warning,
                        hideOnClickOutSide: true,
                        content: 'Folder not found.',
                        show: true
                    }
                }))
            }

            if (response.data.flag_status === 'invalid-json-token') {
                handleSetLoginContext(null);
            }

            if (response.data.flag_status === 'user-inactive') {
                handleSetLoginContext(null);
            }
        }).catch(error => {
            if (!mountedRef.current) return null;

            setData(d => ({
                ...d,
                dataLoading: false,
                // dataSearchInputLoading: false
            }));

            notifications.show({
                id: notificationId.current.errorGetData,
                title: utils.notification.warning.caption,
                message: error.message,
                color: utils.notification.warning.color,
                icon: utils.notification.warning.icon,
                withBorder: true,
                autoClose: true,
            })
        })
    }, [handleSetLoginContext, loginContext, data.dataRefresh, data.dataOffset, orderType, orderBy, orderingItem, data.folderParentId])

    React.useEffect(() => {
        if (data.details.show) {
            // Comp1 narrows and Comp2 widens when details is shown
            controlsComp1.start({ flex: matchesMinXL ? .775 : (matchesMinLG ? .725 : .65), opacity: 1 });
            controlsComp2.start({ flex: matchesMinXL ? .225 : (matchesMinLG ? .275 : .35), opacity: 1 });
        } else {
            // Comp1 widens and Comp2 narrows when details is hidden
            controlsComp1.start({ flex: 1, opacity: 1 });
            controlsComp2.start({ flex: 0, opacity: 0 });
        }
    }, [data.details.show, controlsComp1, controlsComp2, matchesMinLG, matchesMinXL]);

    React.useEffect(() => {
        if (matchesMaxMD) {
            setData(d => ({ ...d, details: { ...d.details, show: false } }))
        }
    }, [matchesMaxMD])

    React.useEffect(() => {
        console.log('PF', data.folderParentId)
        setData(d => ({
            ...d,
            items: { ...d.items, selected: [] }
        }));
    }, [data.folderParentId])

    const handleSelectedItems = (obj, e) => {
        if (e.ctrlKey) {
            const cekIsSet = data.items.selected.some(item => item.item_id === obj.item_id);
            let res = [];

            if (cekIsSet) {
                res = data.items.selected.filter(item => item.item_id !== obj.item_id);
            } else {
                res = data.items.selected.concat(obj);
            }

            setData(d => ({ ...d, items: { ...d.items, selected: res } }));
        } else {
            setData(d => ({ ...d, items: { ...d.items, selected: [obj] } }));
        }
    }

    const handleRemoveSelected = () => {
        setData(d => ({ ...d, items: { ...d.items, selected: [] } }));
    }

    const handleHoldItem = (obj) => {
        const cekIsSet = data.items.selected.some(item => item.item_id === obj.item_id);
        let res = [];

        if (cekIsSet) {
            res = data.items.selected.filter(item => item.item_id !== obj.item_id);
        } else {
            res = data.items.selected.concat(obj);
        }

        setData(d => ({ ...d, items: { ...d.items, selected: res } }));
    }

    const handleOnDoubleClick = (obj) => {
        setData(d => ({
            ...d,
            folderParentId: [...d.folderParentId, obj.item_id],
        }));
    }

    const isFilesOnly = (arrObj) => {
        let res = false;

        try {
            const cek = arrObj.some(item => item.master_type === 'folder');

            if (cek) {
                res = false;
            } else {
                res = true;
            }
        } catch (error) {
            res = false;
        }

        return res;
    }

    const getValueUpToIndex = (stateIndex) => {
        // const res = data.dataPath.slice(0, index);

        let res = [];

        for (let index = 0; index <= stateIndex; index++) {
            res.push(data.dataPath[index]);
        }

        let resArrPath = [];

        if (Array.isArray(res) && (res.length > 0)) {
            resArrPath = res.map(item => item.folder_id);
            resArrPath.unshift(null);
        } else {
            resArrPath = [null];
        }

        setData(d => ({ ...d, folderParentId: resArrPath }))
    }

    const handleSuccessRenameFolder = () => {
        setData(d => ({ ...d, dataRefresh: new Date() }))
    }

    const handleRenameFolderUtils = (obj) => {
        setData(d => ({ ...d, renameFolder: { ...d.renameFolder, id: obj.item_id, name: obj.item_name } }))
    }

    //

    // Fungsi untuk membuat file ZIP dari berkas dan folder yang dipilih
    const createZip = async (selectedFiles) => {
        const zip = new JSZip();

        // Fungsi rekursif untuk menambahkan berkas dan folder ke ZIP
        const addContentToZip = async (content, path = '') => {
            if (content.isFolder) {
                const folder = zip.folder(`${path}/${content.name}`);
                await Promise.all(content.children.map(child => addContentToZip(child, `${path}/${content.name}`)));
                return folder;
            } else {
                try {
                    const response = await fetch(content.url);
                    if (!response.ok) {
                        // throw new Error(`Failed to download ${content.name}`);
                        console.log(`Failed to download ${content.name}`)
                    }
                    const fileData = await response.blob();
                    zip.file(`${path}/${content.name}`, fileData);
                } catch (error) {
                    console.error('Error downloading file:', error);
                    // Di sini Anda bisa menambahkan log atau pesan kesalahan kepada pengguna
                    // Misalnya, menampilkan pesan kesalahan pada antarmuka pengguna
                }
            }
        };

        await Promise.all(selectedFiles.map(file => addContentToZip(file)));

        return zip;
    };

    // Memulai proses unduhan
    const handleDownload = async (selectedFiles) => {
        const zip = await createZip(selectedFiles);
        zip.generateAsync({ type: 'blob' })
            .then((blob) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'selected_files.zip';
                link.click();
            });
    };

    const selectedFiles = [
        {
            name: 'favicon.ico',
            isFolder: false,
            url: 'https://veccani.com/documents/gumi/faviconX.ico'
        },
        {
            name: 'hahaX',
            isFolder: true,
            children: [
                {
                    name: 'logo192.png',
                    isFolder: false,
                    url: 'https://veccani.com/documents/gumi/haha/logo192.png'
                },
                {
                    name: 'yuyu.zip',
                    isFolder: false,
                    url: 'https://veccani.com/documents/gumi/haha/yuyu.zip'
                }
            ]
        },
        {
            name: 'yuyu.zip',
            isFolder: false,
            url: 'https://veccani.com/documents/gumi/yuyu.zip'
        }
    ];

    return (
        <React.Fragment>
            <AppShell
                styles={{
                    main: {
                        background: theme.colorScheme === 'light' ? utils.theme.light.mainColor : theme.colors.dark[8],
                        paddingTop: 'calc(var(--mantine-header-height, 0px))',
                        paddingBottom: 'calc(var(--mantine-footer-height, 0px))',
                        paddingLeft: 'calc(var(--mantine-navbar-width, 0px))',
                        paddingRight: 'calc(var(--mantine-aside-width, 0px))',
                        position: 'fixed',
                    },
                }}

                header={<HeaderComponent />}

                navbarOffsetBreakpoint="sm"

                navbar={
                    <NavBarComponent
                        stateData={{
                            path: data.folderParentId
                        }}

                        fnSuccessCreateNewFolder={() => {
                            setData(d => ({ ...d, dataRefresh: new Date() }))
                        }}

                        fnSuccessUploadFiles={() => {
                            setData(d => ({ ...d, dataRefresh: new Date() }))
                        }}
                    />
                }
            >
                <Group
                    noWrap
                    align='flex-start'
                    spacing={0}
                    sx={{
                        userSelect: 'none'
                    }}
                >
                    <motion.div
                        style={{ flex: 1 }}
                        animate={controlsComp1}
                        initial={{ flex: 1, opacity: 1 }}
                        transition={{ duration: 0.35 }}
                    >
                        <OutsideComponentHandler
                            handleRemoveSelected={handleRemoveSelected}
                        >
                            <ScrollArea
                                sx={(tema) => ({
                                    background: theme.colorScheme === 'light' ? '#fff' : theme.colors.dark[7],
                                    height: `calc(${debounced}px - var(--mantine-header-height, 0rem) - var(--mantine-footer-height, 0rem) - .75rem)`,
                                    borderRadius: tema.radius.md,
                                })}

                                p='md'
                                mr={matchesMinSM ? 'sm' : '1rem'}
                            >
                                <Box
                                    mb='0'
                                    pb='xs'
                                    sx={(tema) => ({
                                        position: 'sticky',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        zIndex: 1,

                                        backgroundColor: tema.colorScheme === 'light' ? '#fff' : tema.colors.dark[7]
                                    })}

                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Group
                                        noWrap
                                        spacing={3}
                                        mb='sm'
                                    >
                                        {Array.isArray(data.folderParentId) && (data.folderParentId.length <= 1) &&
                                            <Menu shadow="md" width={200}>
                                                <Menu.Target>
                                                    <Button
                                                        variant="outline"
                                                        size='sm'
                                                        radius="xl"

                                                        sx={(tema) => ({
                                                            borderColor: theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.dark[4],
                                                            color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.dark[0],
                                                            '&:hover': {
                                                                backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                                                            },
                                                        })}
                                                        rightIcon={
                                                            <IconChevronDown
                                                                size={18}
                                                                strokeWidth={1.5}
                                                            />
                                                        }

                                                        styles={(tema) => ({
                                                            rightIcon: {
                                                                // marginLeft: 0
                                                            }
                                                        })}
                                                    >
                                                        <Text>
                                                            My Drive
                                                        </Text>
                                                    </Button>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    <Menu.Item
                                                        icon={<IconFolderPlus size={14} />}
                                                        onClick={() => {
                                                            setData(d => ({
                                                                ...d,
                                                                newFolder: { ...d.newFolder, show: true }
                                                            }))
                                                        }}
                                                    >
                                                        New Folder
                                                    </Menu.Item>

                                                    <Menu.Divider />

                                                    <Menu.Item
                                                        icon={<IconFile size={14} />}
                                                        onClick={() => openRef.current()}
                                                    >
                                                        File Upload
                                                    </Menu.Item>

                                                    {/* <Menu.Item icon={<IconFolder size={14} />}>Folder Upload</Menu.Item> */}
                                                </Menu.Dropdown>
                                            </Menu>
                                        }

                                        {/* batas */}

                                        {Array.isArray(data.folderParentId) && (data.folderParentId.length > 1) &&
                                            <Group
                                                noWrap
                                                spacing={3}
                                            >
                                                <Menu
                                                    shadow="md"
                                                    width={250}
                                                >
                                                    <Menu.Target>
                                                        <ActionIcon
                                                            variant='subtle'
                                                            radius='xl'
                                                            size='2.5rem'
                                                            sx={{
                                                                border: 0,
                                                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                                '&:hover': {
                                                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                                                                }
                                                            }}
                                                            title={'Path'}
                                                        >
                                                            <IconDots
                                                                size={20}
                                                                strokeWidth={2}
                                                            />
                                                        </ActionIcon>
                                                    </Menu.Target>

                                                    <Menu.Dropdown>
                                                        <ScrollArea.Autosize
                                                            sx={(tema) => ({
                                                                // height: '100%',
                                                                maxHeight: 200
                                                            })}
                                                        >
                                                            <Menu.Item
                                                                onClick={() => {
                                                                    setData(d => ({ ...d, folderParentId: [null] }))
                                                                }}
                                                            >
                                                                <Group
                                                                    noWrap
                                                                    sx={(tema) => ({
                                                                        color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.dark[1]
                                                                    })}
                                                                    spacing='xs'
                                                                >
                                                                    <IconFolderFilled
                                                                        size='24px'
                                                                        strokeWidth={1.5}
                                                                    />

                                                                    <Text
                                                                        sx={(tema) => ({
                                                                            color: tema.colorScheme === 'light' ? tema.colors.gray[8] : tema.colors.dark[1],
                                                                            width: '100%',
                                                                            wordBreak: 'break-all'
                                                                        })}

                                                                        lineClamp={1}
                                                                    >
                                                                        My Drive
                                                                    </Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            {Array.isArray(data.dataPath) && (data.dataPath.length > 1) && Object.keys(data.dataPath.slice(0, data.dataPath.length - 1)).map((keyPath, indexPath) => {
                                                                return (
                                                                    <Menu.Item
                                                                        key={keyPath}
                                                                        onClick={() => {
                                                                            getValueUpToIndex(keyPath);
                                                                        }}
                                                                    >
                                                                        <Group
                                                                            noWrap
                                                                            sx={(tema) => ({
                                                                                color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.dark[1],
                                                                            })}
                                                                            spacing='xs'
                                                                        >
                                                                            <IconFolderFilled
                                                                                size='24px'
                                                                                strokeWidth={1.5}
                                                                            />

                                                                            <Text
                                                                                sx={(tema) => ({
                                                                                    color: tema.colorScheme === 'light' ? tema.colors.gray[8] : tema.colors.dark[1],
                                                                                    width: '100%',
                                                                                    wordBreak: 'break-all'
                                                                                })}
                                                                                lineClamp={1}
                                                                            >
                                                                                {data.dataPath[keyPath].folder_name}
                                                                            </Text>
                                                                        </Group>
                                                                    </Menu.Item>
                                                                )
                                                            })}
                                                        </ScrollArea.Autosize>
                                                    </Menu.Dropdown>
                                                </Menu>

                                                <IconChevronRight
                                                    size={16}
                                                    strokeWidth={1.5}
                                                    color={theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.dark[2]}
                                                />

                                                <Menu shadow="md" width={200}>
                                                    <Menu.Target>
                                                        <Button
                                                            variant="subtle"
                                                            size='sm'
                                                            radius="xl"

                                                            sx={(tema) => ({
                                                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.dark[0],
                                                                '&:hover': {
                                                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                                                                },
                                                            })}
                                                            rightIcon={
                                                                <IconChevronDown
                                                                    size={18}
                                                                    strokeWidth={1.5}
                                                                />
                                                            }

                                                            styles={(tema) => ({
                                                                rightIcon: {
                                                                    marginLeft: '.25rem'
                                                                }
                                                            })}
                                                        >
                                                            <Text
                                                                sx={{
                                                                    maxWidth: matchesMinSM ? (matchesMinMD ? (matchesMinLG ? (matchesMinXL ? '500px' : '400px') : '300px') : '200px') : '100px',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis'
                                                                }}
                                                            >
                                                                {Array.isArray(data.dataPath) && (data.dataPath.length > 0) ? data.dataPath[data.dataPath.length - 1].folder_name : '...'}
                                                            </Text>
                                                        </Button>
                                                    </Menu.Target>

                                                    <Menu.Dropdown>
                                                        <ScrollArea.Autosize
                                                            sx={(tema) => ({
                                                                // height: '100%',
                                                                maxHeight: 200
                                                            })}
                                                        >
                                                            <Menu.Item
                                                                onClick={() => {
                                                                    setData(d => ({
                                                                        ...d,
                                                                        newFolder: { ...d.newFolder, show: true }
                                                                    }))
                                                                }}
                                                            >
                                                                <Group noWrap>
                                                                    <IconFolderPlus size={18} strokeWidth={1.5} />
                                                                    <Text>New folder</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            <Menu.Divider />

                                                            <Menu.Item
                                                                onClick={() => {
                                                                    // handleDownload(selectedFiles);
                                                                }}
                                                            >
                                                                <Group noWrap>
                                                                    <IconDownload size={18} strokeWidth={1.5} />
                                                                    <Text>Download</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            {Array.isArray(data.items.selected) && (data.items.selected.length <= 1) &&
                                                                <Menu.Item
                                                                    onClick={() => {
                                                                        setData(d => ({
                                                                            ...d,
                                                                            renameFolderParent: {
                                                                                ...d.renameFolderParent,
                                                                                id: Array.isArray(data.dataPath) && (data.dataPath.length > 0) ? data.dataPath[data.dataPath.length - 1].folder_id : 'dummy_id_jika_ada_kesalahan_(prevent_database)',
                                                                                name: Array.isArray(data.dataPath) && (data.dataPath.length > 0) ? data.dataPath[data.dataPath.length - 1].folder_name : '',
                                                                                modal: true
                                                                            }
                                                                        }))
                                                                    }}
                                                                >
                                                                    <Group noWrap>
                                                                        <IconPencil size={18} strokeWidth={1.5} />
                                                                        <Text>Rename</Text>
                                                                    </Group>
                                                                </Menu.Item>
                                                            }

                                                            <Menu.Divider />

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconUserPlus size={18} strokeWidth={1.5} />
                                                                    <Text>Share</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconLink size={18} strokeWidth={1.5} />
                                                                    <Text>Copy link</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            <Menu.Divider />

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconFolderShare size={18} strokeWidth={1.5} />
                                                                    <Text>Move</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconStar size={18} strokeWidth={1.5} />
                                                                    <Text>Add to starred</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            {Array.isArray(data.items.selected) && (data.items.selected.length <= 1) &&
                                                                <Menu.Item>
                                                                    <Group noWrap>
                                                                        <IconInfoCircle size={18} strokeWidth={1.5} />
                                                                        <Text>Details</Text>
                                                                    </Group>
                                                                </Menu.Item>
                                                            }

                                                            <Menu.Divider />

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconTrash size={18} strokeWidth={1.5} />
                                                                    <Text>Move to trash</Text>
                                                                </Group>
                                                            </Menu.Item>
                                                        </ScrollArea.Autosize>
                                                    </Menu.Dropdown>
                                                </Menu>
                                            </Group>
                                        }

                                        <Group noWrap ml='auto' mr='sm'>
                                            <DisplayModeSelector />

                                            {matchesMinMD ?
                                                <ActionIcon
                                                    variant='subtle'
                                                    radius='xl'
                                                    size='lg'
                                                    sx={{
                                                        border: 0,
                                                        color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                        backgroundColor: 'transparent',
                                                        transition: '.25s',
                                                        '&:hover': {
                                                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                                                        }
                                                    }}
                                                    title={'Details'}

                                                    onClick={() => {
                                                        setData(d => ({ ...d, details: { ...d.details, show: !d.details.show } }))
                                                    }}
                                                >
                                                    <IconInfoCircle
                                                        size={20}
                                                        strokeWidth={2}
                                                    />
                                                </ActionIcon>
                                                :
                                                <ActionIcon
                                                    variant='subtle'
                                                    radius='xl'
                                                    size='lg'
                                                    sx={{
                                                        border: 0,
                                                        color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                        backgroundColor: 'transparent',
                                                        transition: '.25s',
                                                        '&:hover': {
                                                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                                                        }
                                                    }}
                                                    title={'Details'}

                                                    onClick={() => {
                                                        setData(d => ({ ...d, details: { ...d.details, drawer: true } }))
                                                    }}
                                                >
                                                    <IconInfoCircle
                                                        size={20}
                                                        strokeWidth={2}
                                                    />
                                                </ActionIcon>
                                            }
                                        </Group>
                                    </Group>

                                    {Array.isArray(data.items.selected) && (data.items.selected.length <= 0) &&
                                        <Group
                                            spacing='.5rem'
                                            sx={(tema) => ({
                                                minHeight: minHeightFilterUtilsBar,
                                            })}
                                        >
                                            <Menu shadow="md" width={200}>
                                                <Menu.Target>
                                                    <Button
                                                        variant="outline"
                                                        size='xs'
                                                        radius="md"

                                                        sx={(tema) => ({
                                                            borderColor: theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.dark[4],
                                                            color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.dark[0],
                                                        })}
                                                        rightIcon={
                                                            <IconChevronDown
                                                                size={16}
                                                                strokeWidth={1.5}
                                                            />
                                                        }
                                                    >
                                                        Type
                                                    </Button>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    <Menu.Item icon={<IconArticle size={14} />}>All</Menu.Item>
                                                    <Menu.Item icon={<IconFile size={14} />}>File</Menu.Item>
                                                    <Menu.Item icon={<IconFolder size={14} />}>Folder</Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>

                                            <Menu shadow="md" width={200}>
                                                <Menu.Target>
                                                    <Button
                                                        variant="outline"
                                                        size='xs'
                                                        radius="md"

                                                        sx={(tema) => ({
                                                            borderColor: theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.dark[4],
                                                            color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.dark[0],
                                                        })}
                                                        rightIcon={
                                                            <IconChevronDown
                                                                size={16}
                                                                strokeWidth={1.5}
                                                            />
                                                        }
                                                    >
                                                        People
                                                    </Button>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    <Menu.Item icon={<IconUserCircle size={18} stroke={1.5} />}>Obama</Menu.Item>
                                                    <Menu.Item icon={<IconUserCircle size={18} stroke={1.5} />}>Puttin</Menu.Item>
                                                    <Menu.Item icon={<IconUserCircle size={18} stroke={1.5} />}>Trump</Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>

                                            <Menu shadow="md" width={200}>
                                                <Menu.Target>
                                                    <Button
                                                        variant="outline"
                                                        size='xs'
                                                        radius="md"

                                                        sx={(tema) => ({
                                                            borderColor: theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.dark[4],
                                                            color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.dark[0],
                                                        })}
                                                        rightIcon={
                                                            <IconChevronDown
                                                                size={16}
                                                                strokeWidth={1.5}
                                                            />
                                                        }
                                                    >
                                                        Modified
                                                    </Button>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    <Menu.Item>Today</Menu.Item>
                                                    <Menu.Item>Last 7 days</Menu.Item>
                                                    <Menu.Item>Last 30 days</Menu.Item>
                                                    <Menu.Item>This year (2023)</Menu.Item>
                                                    <Menu.Item>Last year (2022)</Menu.Item>
                                                    <Menu.Item>Custom date range</Menu.Item>
                                                </Menu.Dropdown>
                                            </Menu>
                                        </Group>
                                    }

                                    {Array.isArray(data.items.selected) && (data.items.selected.length > 0) &&
                                        <Group
                                            spacing='.5rem'
                                            px='.35rem'
                                            py='.35rem'
                                            sx={(tema) => ({
                                                minHeight: minHeightFilterUtilsBar,
                                                backgroundColor: tema.colorScheme === 'light' ? utils.theme.light.folderColor : tema.colors.dark[6],
                                                borderRadius: tema.radius.md
                                            })}
                                        >
                                            <ActionIcon
                                                variant='subtle'
                                                radius='xl'
                                                size='md'
                                                sx={{
                                                    border: 0,
                                                    color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                    transition: '.25s',
                                                    '&:hover': {
                                                        backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[5],
                                                    }
                                                }}
                                                title={'Clear Selection'}

                                                onClick={() => {
                                                    setData(d => ({ ...d, items: { ...d.items, selected: [] } }))
                                                }}
                                            >
                                                <IconX
                                                    size={18}
                                                    strokeWidth={1.5}
                                                />
                                            </ActionIcon>

                                            <Text
                                                size='sm'
                                            >
                                                {data.items.selected.length} selected
                                            </Text>

                                            <Group
                                                spacing='.5rem'
                                            >
                                                {matchesMinXS &&
                                                    <Group
                                                        spacing='.5rem'
                                                    >
                                                        <ActionIcon
                                                            variant='subtle'
                                                            radius='xl'
                                                            size='md'
                                                            sx={{
                                                                border: 0,
                                                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                                transition: '.25s',
                                                                '&:hover': {
                                                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[5],
                                                                }
                                                            }}
                                                            title={'Share item'}

                                                            onClick={() => {
                                                                // setData(d => ({ ...d, items: { ...d.items, selected: [] } }))
                                                            }}
                                                        >
                                                            <IconUserPlus
                                                                size={16}
                                                                strokeWidth={2}
                                                            />
                                                        </ActionIcon>

                                                        <ActionIcon
                                                            variant='subtle'
                                                            radius='xl'
                                                            size='md'
                                                            sx={{
                                                                border: 0,
                                                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                                transition: '.25s',
                                                                '&:hover': {
                                                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[5],
                                                                }
                                                            }}
                                                            title={'Download'}

                                                            onClick={() => {
                                                                // setData(d => ({ ...d, items: { ...d.items, selected: [] } }))
                                                            }}
                                                        >
                                                            <IconDownload
                                                                size={16}
                                                                strokeWidth={2}
                                                            />
                                                        </ActionIcon>

                                                        <ActionIcon
                                                            variant='subtle'
                                                            radius='xl'
                                                            size='md'
                                                            sx={{
                                                                border: 0,
                                                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                                transition: '.25s',
                                                                '&:hover': {
                                                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[5],
                                                                }
                                                            }}
                                                            title={'Move'}

                                                            onClick={() => {
                                                                // setData(d => ({ ...d, items: { ...d.items, selected: [] } }))
                                                            }}
                                                        >
                                                            <IconFolderShare
                                                                size={16}
                                                                strokeWidth={2}
                                                            />
                                                        </ActionIcon>

                                                        <ActionIcon
                                                            variant='subtle'
                                                            radius='xl'
                                                            size='md'
                                                            sx={{
                                                                border: 0,
                                                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                                transition: '.25s',
                                                                '&:hover': {
                                                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[5],
                                                                }
                                                            }}
                                                            title={'Move to trash'}

                                                            onClick={() => {
                                                                // setData(d => ({ ...d, items: { ...d.items, selected: [] } }))
                                                            }}
                                                        >
                                                            <IconTrash
                                                                size={16}
                                                                strokeWidth={2}
                                                            />
                                                        </ActionIcon>

                                                        <ActionIcon
                                                            variant='subtle'
                                                            radius='xl'
                                                            size='md'
                                                            sx={{
                                                                border: 0,
                                                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                                transition: '.25s',
                                                                '&:hover': {
                                                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[5],
                                                                }
                                                            }}
                                                            title={'Copy link'}

                                                            onClick={() => {
                                                                // setData(d => ({ ...d, items: { ...d.items, selected: [] } }))
                                                            }}
                                                        >
                                                            <IconLink
                                                                size={16}
                                                                strokeWidth={2}
                                                            />
                                                        </ActionIcon>
                                                    </Group>
                                                }

                                                <Menu
                                                    shadow="md"
                                                    width={250}
                                                >
                                                    <Menu.Target>
                                                        <ActionIcon
                                                            variant='subtle'
                                                            radius='xl'
                                                            size='sm'
                                                            sx={{
                                                                border: 0,
                                                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                                '&:hover': {
                                                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[5],
                                                                }
                                                            }}
                                                            title={'More actions'}
                                                        >
                                                            <IconDotsVertical
                                                                size={16}
                                                                strokeWidth={2}
                                                            />
                                                        </ActionIcon>
                                                    </Menu.Target>

                                                    <Menu.Dropdown>
                                                        <ScrollArea.Autosize
                                                            sx={(tema) => ({
                                                                // height: '100%',
                                                                maxHeight: 200
                                                            })}
                                                        >
                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconDownload size={18} strokeWidth={1.5} />
                                                                    <Text>Download</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            {Array.isArray(data.items.selected) && (data.items.selected.length <= 1) &&
                                                                <Menu.Item
                                                                    onClick={() => {
                                                                        setData(d => ({ ...d, renameFolder: { ...d.renameFolder, modal: true } }))
                                                                    }}
                                                                >
                                                                    <Group noWrap>
                                                                        <IconPencil size={18} strokeWidth={1.5} />
                                                                        <Text>Rename</Text>
                                                                    </Group>
                                                                </Menu.Item>
                                                            }

                                                            {isFilesOnly(data.items.selected) &&
                                                                <Menu.Item>
                                                                    <Group noWrap>
                                                                        <IconFiles size={18} strokeWidth={1.5} />
                                                                        <Text>Make a copy</Text>
                                                                    </Group>
                                                                </Menu.Item>
                                                            }

                                                            <Menu.Divider />

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconUserPlus size={18} strokeWidth={1.5} />
                                                                    <Text>Share</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconLink size={18} strokeWidth={1.5} />
                                                                    <Text>Copy link</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            <Menu.Divider />

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconFolderShare size={18} strokeWidth={1.5} />
                                                                    <Text>Move</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconStar size={18} strokeWidth={1.5} />
                                                                    <Text>Add to starred</Text>
                                                                </Group>
                                                            </Menu.Item>

                                                            {Array.isArray(data.items.selected) && (data.items.selected.length <= 1) &&
                                                                <Menu.Item>
                                                                    <Group noWrap>
                                                                        <IconInfoCircle size={18} strokeWidth={1.5} />
                                                                        <Text>Details</Text>
                                                                    </Group>
                                                                </Menu.Item>
                                                            }

                                                            <Menu.Divider />

                                                            <Menu.Item>
                                                                <Group noWrap>
                                                                    <IconTrash size={18} strokeWidth={1.5} />
                                                                    <Text>Move to trash</Text>
                                                                </Group>
                                                            </Menu.Item>
                                                        </ScrollArea.Autosize>
                                                    </Menu.Dropdown>
                                                </Menu>
                                            </Group>
                                        </Group>
                                    }
                                </Box>
                                {/* sticky element */}

                                {Array.isArray(data.data) && (data.data.length > 0) &&
                                    <Group spacing={2} mb='md'>
                                        <Text
                                            fw='500'
                                            size='sm'
                                            sx={{ userSelect: 'none' }}
                                        >
                                            <ItemOrderingTypeCaption
                                                stateData={data.data}
                                            />
                                        </Text>

                                        <Group spacing={2} ml='auto'>
                                            <OrderTypeSelector
                                                fnRemoveItemsSelected={handleRemoveSelected}
                                            />

                                            <OrderBySelector
                                                fnRemoveItemsSelected={handleRemoveSelected}
                                            />

                                            <OrderingItemSelector
                                                fnRemoveItemsSelected={handleRemoveSelected}
                                            />
                                        </Group>
                                    </Group>
                                }

                                {Array.isArray(data.data) && (data.data.length > 0) ?
                                    <ItemsComponent
                                        stateData={data.data}
                                        stateItemsSelected={data.items.selected}
                                        stateIsShowDetails={data.details.show}
                                        fnSelected={(obj, e) => handleSelectedItems(obj, e)}
                                        fnHoldItem={(obj) => handleHoldItem(obj)}
                                        fnOnDoubleClick={(obj) => handleOnDoubleClick(obj)}
                                        fnSuccessRenameFolder={() => handleSuccessRenameFolder()}
                                        fnRenameFolderUtils={(obj) => handleRenameFolderUtils(obj)}
                                    />
                                    :
                                    <NoData />
                                }
                            </ScrollArea>
                        </OutsideComponentHandler>
                    </motion.div>

                    {data.details.show && matchesMinMD &&
                        <motion.div
                            style={{ flex: 0 }}
                            animate={controlsComp2}
                            initial={{ flex: 0, opacity: 0 }}
                            transition={{ duration: 0.35 }}
                        >
                            <ScrollArea
                                sx={(tema) => ({
                                    background: theme.colorScheme === 'light' ? '#fff' : theme.colors.dark[7],
                                    height: `calc(${debounced}px - var(--mantine-header-height, 0rem) - var(--mantine-footer-height, 0rem) - .75rem)`,
                                    borderRadius: tema.radius.md,
                                })}

                                p='md'
                                mr={matchesMinSM ? 'sm' : '0'}
                            >
                                <Box
                                    mb='md'
                                    pb='xs'
                                    sx={(tema) => ({
                                        position: 'sticky',
                                        top: 0,
                                        left: 0,
                                        right: 0,

                                        backgroundColor: tema.colorScheme === 'light' ? '#fff' : tema.colors.dark[7]
                                    })}
                                >
                                    <Group
                                        align='flex-start'
                                    >
                                        <IconFolder size={18} stroke={1.5} style={{ marginTop: '3px' }} />

                                        <Text
                                            sx={{ flex: 1, width: '100%' }}
                                            lineClamp={5}
                                        >
                                            Folder Name
                                        </Text>

                                        <ActionIcon
                                            variant='subtle'
                                            radius='xl'
                                            size='md'
                                            mt={0}
                                            sx={{
                                                zIndex: `99999 !important`,
                                                border: 0,
                                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                                backgroundColor: theme.colorScheme === 'light' ? '#fff' : theme.colors.dark[6],
                                                transition: '.25s',
                                                '&:hover': {
                                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                                                }
                                            }}
                                            title={'Close'}
                                            onClick={() => {
                                                setData(d => ({ ...d, details: { ...d.details, show: false } }))
                                            }}
                                        >
                                            <IconX
                                                size={18}
                                                strokeWidth={1.5}
                                            />
                                        </ActionIcon>
                                    </Group>

                                    <Tabs
                                        mt='sm'
                                        value={data.details.tabSelected}
                                        onTabChange={(val) => {
                                            setData(d => ({ ...d, details: { ...d.details, tabSelected: val } }))
                                        }}
                                    >
                                        <Tabs.List grow>
                                            <Tabs.Tab value={utils.details.tabDetails.value}>Details</Tabs.Tab>
                                            <Tabs.Tab value={utils.details.tabActivity.value}>Activity</Tabs.Tab>
                                        </Tabs.List>
                                    </Tabs>
                                </Box>

                                {data.details.tabSelected === utils.details.tabDetails.value &&
                                    <DetailsDetails />
                                }

                                {data.details.tabSelected === utils.details.tabActivity.value &&
                                    <DetailsActivity />
                                }
                            </ScrollArea>
                        </motion.div>
                    }
                </Group>
            </AppShell>

            <Drawer
                opened={data.details.drawer}
                onClose={() => setData(d => ({ ...d, details: { ...d.details, drawer: false } }))}
                title={
                    <React.Fragment>
                        <Group
                            align='flex-start'
                        >
                            <IconFolder size={18} stroke={1.5} style={{ marginTop: '3px' }} />

                            <Text
                                sx={{ flex: 1, width: '100%' }}
                                lineClamp={5}
                            >
                                Folder Name
                            </Text>
                        </Group>
                    </React.Fragment>
                }
                size='xs'
                position='right'
                withCloseButton={true}

                sx={() => ({
                    '& .mantine-Paper-root.mantine-Drawer-content::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                    },

                    '& .mantine-Paper-root.mantine-Drawer-content::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent'
                    },

                    '& .mantine-Paper-root.mantine-Drawer-content::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[5] : theme.colors.dark[4],
                        borderRadius: '4px'
                    },

                    '& .mantine-Paper-root.mantine-Drawer-content::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[6] : theme.colors.dark[3],
                    },
                })}
            >
                <Tabs
                    value={data.details.tabSelected}
                    onTabChange={(val) => {
                        setData(d => ({ ...d, details: { ...d.details, tabSelected: val } }))
                    }}

                    mb='sm'
                >
                    <Tabs.List grow>
                        <Tabs.Tab value={utils.details.tabDetails.value}>Details</Tabs.Tab>
                        <Tabs.Tab value={utils.details.tabActivity.value}>Activity</Tabs.Tab>
                    </Tabs.List>
                </Tabs>

                {data.details.tabSelected === utils.details.tabDetails.value &&
                    <DetailsDetails />
                }

                {data.details.tabSelected === utils.details.tabActivity.value &&
                    <DetailsActivity />
                }
            </Drawer>

            <FnNewFolder
                stateShow={data.newFolder.show}
                fnShow={(val) => setData(d => ({ ...d, newFolder: { ...d.newFolder, show: val } }))}

                stateData={{
                    path: data.folderParentId
                }}

                fnSuccessCreate={() => {
                    setData(d => ({
                        ...d,
                        newFolder: { ...d.newFolder, show: false },
                        dataRefresh: new Date()
                    }))
                }}
            />

            <FileSelectorComponent
                stateRef={openRef}
                stateData={{
                    parentFolderArray: data.folderParentId
                }}
                fnSuccessUpload={() => {
                    setData(d => ({ ...d, dataRefresh: new Date() }))
                }}
            />

            <ModalResponse
                stateShow={data.modalResponse.show}
                fnHide={() => setData(d => ({
                    ...d,
                    modalResponse: {
                        ...d.modalResponse,
                        show: false
                    }
                }))}

                stateHideOnClickOutSide={data.modalResponse.hideOnClickOutSide}
                stateType={data.modalResponse.type}
                stateContent={data.modalResponse.content}
            />

            <FnRenameFolder
                stateShow={data.renameFolderParent.modal}
                fnShow={() => setData(d => ({ ...d, renameFolderParent: { ...d.renameFolderParent, modal: false } }))}
                stateData={{
                    id: data.renameFolderParent.id,
                    name: data.renameFolderParent.name
                }}
                fnSuccessRenameFolder={() => {
                    setData(d => ({ ...d, renameFolderParent: { ...d.renameFolderParent, modal: false } }));
                    handleSuccessRenameFolder();
                }}
            />

            <FnRenameFolder
                stateShow={data.renameFolder.modal}
                fnShow={() => setData(d => ({ ...d, renameFolder: { ...d.renameFolder, modal: false } }))}
                stateData={{
                    id: data.renameFolder.id,
                    name: data.renameFolder.name
                }}
                fnSuccessRenameFolder={() => {
                    setData(d => ({ ...d, renameFolder: { ...d.renameFolder, modal: false } }));
                    handleSuccessRenameFolder();
                }}
            />
        </React.Fragment>
    )
}

export default MainComponent;