import React from "react";
import { Menu, Text, Group, Button } from '@mantine/core';
import { IconArrowUp, IconArrowDown, IconChevronDown } from '@tabler/icons-react';

import { utils } from "../../utils/globalVariables";
import { useOrderByContext } from "../../context/orderByContext";
import { useOrderTypeContext } from "../../context/orderTypeContext";

const OrderBySelector = ({ fnRemoveItemsSelected }) => {

    const { orderBy, handleSetOrderByContext } = useOrderByContext();
    const { orderType } = useOrderTypeContext();

    return (
        <React.Fragment>
            <Menu
                shadow="md"
                width={250}
            >
                <Menu.Target>
                    <Button
                        radius='lg'
                        size="xs"
                        variant="subtle"
                        color="gray"

                        sx={(tema) => ({
                            color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.gray[5],
                            '&:hover': {
                                backgroundColor: tema.colorScheme === 'light' ? tema.colors.gray[2] : tema.colors.dark[6],
                            }
                        })}

                        onClick={fnRemoveItemsSelected}
                    >
                        <Text
                            mr='.25rem'
                            sx={(tema) => ({
                                color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.gray[5]
                            })}
                        >
                            Name
                        </Text>
                        <IconChevronDown
                            size={16}
                            strokeWidth={1.5}
                        />
                    </Button>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Label>
                        Sort By
                    </Menu.Label>

                    {Object.keys(utils.orderBy).map((keyOB, indexOB) => {
                        return (
                            <Menu.Item
                                key={keyOB}
                                onClick={() => {
                                    handleSetOrderByContext(utils.orderBy[keyOB].value);
                                }}
                            >
                                <Group
                                    noWrap
                                    sx={(tema) => ({
                                        color: orderBy === utils.orderBy[keyOB].value ? (tema.colorScheme === 'light' ? tema.colors.blue[7] : tema.colors.blue[5]) : 'transparent'
                                    })}
                                >
                                    {orderType === utils.orderType.asc.value ?
                                        <IconArrowUp
                                            size={20}
                                            strokeWidth={1.5}
                                        />
                                        :
                                        <IconArrowDown
                                            size={20}
                                            strokeWidth={1.5}
                                        />
                                    }

                                    <Text
                                        sx={(tema) => ({
                                            color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.dark[0]
                                        })}
                                    >
                                        {utils.orderBy[keyOB].caption}
                                    </Text>
                                </Group>
                            </Menu.Item>
                        )
                    })}
                </Menu.Dropdown>
            </Menu>
        </React.Fragment>
    )
}

export default OrderBySelector;