import React from 'react';
import ReactDOM from 'react-dom/client';

import { LoginContextSUProvider } from './context/loginContextSU';
import { LoginContextProvider } from './context/loginContext';
import { DisplayModeContextProvider } from './context/displayModeContext';
import { OrderTypeContextProvider } from './context/orderTypeContext';
import { OrderByContextProvider } from './context/orderByContext';
import { OrderingItemContextProvider } from './context/orderingItemContext';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <LoginContextSUProvider>
    <LoginContextProvider>
      <DisplayModeContextProvider>
        <OrderTypeContextProvider>
          <OrderByContextProvider>
            <OrderingItemContextProvider>
              <App />
            </OrderingItemContextProvider>
          </OrderByContextProvider>
        </OrderTypeContextProvider>
      </DisplayModeContextProvider>
    </LoginContextProvider>
  </LoginContextSUProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
