import React from "react";
import { ActionIcon, useMantineTheme } from '@mantine/core';
import { IconList, IconLayoutGrid } from '@tabler/icons-react';

import { utils } from "../../utils/globalVariables";
import { useDisplayModeContext } from "../../context/displayModeContext";

const DisplayModeSelector = () => {

    const theme = useMantineTheme();
    const { displayMode, handleSetDisplayModeContext } = useDisplayModeContext();

    return (
        <React.Fragment>
            {displayMode === utils.displayMode.card.slug ?
                <ActionIcon
                    variant='subtle'
                    radius='xl'
                    size='lg'
                    sx={{
                        border: 0,
                        color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                        backgroundColor: 'transparent',
                        transition: '.25s',
                        '&:hover': {
                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                        }
                    }}
                    title={'List layout'}
                    onClick={() => {
                        handleSetDisplayModeContext(utils.displayMode.table.slug);
                    }}
                >
                    <IconList
                        size={20}
                        strokeWidth={2}
                    />
                </ActionIcon>
                :
                <ActionIcon
                    variant='subtle'
                    radius='xl'
                    size='lg'
                    sx={{
                        border: 0,
                        color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                        backgroundColor: 'transparent',
                        transition: '.25s',
                        '&:hover': {
                            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                        }
                    }}
                    title={'Grid layout'}
                    onClick={() => {
                        handleSetDisplayModeContext(utils.displayMode.card.slug);
                    }}
                >
                    <IconLayoutGrid
                        size={16}
                        strokeWidth={2}
                    />
                </ActionIcon>
            }
        </React.Fragment>
    )
}

export default DisplayModeSelector;