import React from 'react';

import { Navbar, Text, Group, ScrollArea, Menu, Paper } from '@mantine/core';
import { IconPlus, IconFolderPlus, IconFolder, IconFile } from '@tabler/icons-react';

import { utils } from '../utils/globalVariables';

import MenuList from './MenuList';
import FnNewFolder from '../functions/FnNewFolder';
import FileSelectorComponent from '../components/general/FileSelectorComponent';

const UserButton = React.forwardRef(({ nama, ...others }, ref) => (
    <Paper shadow="sm" p="md" withBorder={false}
        ref={ref}
        sx={(tema) => ({
            display: 'block',
            width: '100%',
            padding: tema.spacing.md,
            backgroundColor: tema.colorScheme === 'light' ? '#fff' : tema.colors.dark[6],
            color: tema.colorScheme === 'light' ? tema.colors.gray[9] : tema.colors.gray[1],
            borderRadius: tema.radius.md,
            transition: '.25s',

            '&:hover': {
                cursor: 'pointer',
                backgroundColor: tema.colorScheme === 'light' ? tema.colors.blue[0] : tema.colors.dark[5],
            },
        })}
        {...others}
    >
        <Group noWrap>
            <IconPlus size="1rem" />

            <Text size="sm" weight={500}>
                New
            </Text>
        </Group>
    </Paper>
));

const NavBarComponent = ({ stateData, fnSuccessCreateNewFolder, fnSuccessUploadFiles }) => {

    // const theme = useMantineTheme();
    const openRef = React.useRef(null);

    const [data, setData] = React.useState({
        newFolder: {
            show: false
        }
    })

    return (
        <React.Fragment>
            <Navbar
                px="md"
                pb="md"
                hiddenBreakpoint="sm"
                hidden
                width={{
                    sm: utils.navbar.width1,
                    xl: utils.navbar.width2,
                }}

                sx={(tema) => ({
                    borderWidth: 0,
                    backgroundColor: tema.colorScheme === 'light' ? utils.theme.light.mainColor : tema.colors.dark[8]
                })}
            >
                <Menu
                    position="right-start"
                    offset={-40}
                >
                    <Navbar.Section>
                        <Menu.Target>
                            <UserButton nama='asdasd' />
                        </Menu.Target>
                    </Navbar.Section>

                    <Menu.Dropdown>
                        <Menu.Item
                            icon={<IconFolderPlus size={14} />}
                            onClick={() => {
                                setData(d => ({
                                    ...d,
                                    newFolder: { ...d.newFolder, show: true }
                                }))
                            }}
                        >
                            New Folder
                        </Menu.Item>

                        <Menu.Divider />

                        <Menu.Item
                            icon={<IconFile size={14} />}
                            onClick={() => openRef.current()}
                        >
                            File Upload
                        </Menu.Item>

                        {/* <Menu.Item icon={<IconFolder size={14} />}>Folder Upload</Menu.Item> */}
                    </Menu.Dropdown>
                </Menu>

                <Navbar.Section
                    grow
                    component={ScrollArea}
                    offsetScrollbars={false}

                    mt='1rem'
                >
                    <MenuList />
                </Navbar.Section>
            </Navbar>

            <FnNewFolder
                stateShow={data.newFolder.show}
                fnShow={(val) => setData(d => ({ ...d, newFolder: { ...d.newFolder, show: val } }))}

                stateData={{
                    path: stateData.path
                }}

                fnSuccessCreate={() => {
                    setData(d => ({
                        ...d,
                        newFolder: { ...d.newFolder, show: false },
                    }));

                    fnSuccessCreateNewFolder();
                }}
            />

            <FileSelectorComponent
                stateRef={openRef}
                stateData={{
                    parentFolderArray: stateData.path
                }}
                fnSuccessUpload={fnSuccessUploadFiles}
            />
        </React.Fragment>
    );
}

export default NavBarComponent;
