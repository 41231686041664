import React from "react";
import { useLocalStorage } from '@mantine/hooks';

import { utils } from "../utils/globalVariables";

const OrderingItemContext = React.createContext({})

export const OrderingItemContextProvider = ({ children }) => {

    const [orderingItem, setOrderingItem] = useLocalStorage({
        key: utils.localStorageName.orderingItem,
        defaultValue: utils.orderingItem.foldersOnTop.value,
        getInitialValueInEffect: false
    });

    const handleSetOrderingItemContext = (param) => {
        setOrderingItem(param);
    }

    return (
        <OrderingItemContext.Provider value={{ orderingItem, handleSetOrderingItemContext }}>
            {children}
        </OrderingItemContext.Provider>
    )
};

export const useOrderingItemContext = () => React.useContext(OrderingItemContext);