import React from "react";
import { Text, Box, SimpleGrid } from '@mantine/core';

import { useOrderingItemContext } from "../context/orderingItemContext";

import { utils } from '../utils/globalVariables';
import { fnBreakPointItems } from '../utils/globalFunctions';
import FolderComponent from '../components/utils/FolderComponent';
import FileComponent from '../components/utils/FileComponent';
import NoData from '../components/general/NoData';

const ItemsComponent = ({ stateData, stateItemsSelected, stateIsShowDetails, fnSelected, fnHoldItem, fnOnDoubleClick, fnSuccessRenameFolder, fnRenameFolderUtils }) => {

    const { orderingItem } = useOrderingItemContext();

    return (
        <React.Fragment>
            {orderingItem === utils.orderingItem.foldersOnTop.value &&
                <React.Fragment>
                    {Array.isArray(stateData) && (stateData.length > 0) &&
                        <SimpleGrid
                            cols={1}
                            spacing='md'
                            breakpoints={fnBreakPointItems(stateIsShowDetails)}
                        >
                            {stateData.filter(item => item.master_type === 'folder').map((item, indexDT) => {
                                return (
                                    <FolderComponent
                                        key={indexDT}
                                        stateData={item}
                                        stateItemsSelected={stateItemsSelected}
                                        fnSelected={fnSelected}
                                        fnHoldItem={fnHoldItem}
                                        fnOnDoubleClick={fnOnDoubleClick}
                                        fnSuccessRenameFolder={fnSuccessRenameFolder}
                                        fnRenameFolderUtils={fnRenameFolderUtils}
                                    />
                                )
                            })}
                        </SimpleGrid>
                    }

                    {stateData.some(obj => obj.master_type === 'file') &&
                        <Box mt={!stateData.some(obj => obj.master_type === 'folder') ? '0' : 'xl'}>
                            {stateData.some(obj => obj.master_type === 'folder') &&
                                <Text fw='500' size='sm' mb='md' sx={{ userSelect: 'none' }}>Files</Text>
                            }

                            {Array.isArray(stateData) && (stateData.length > 0) &&
                                <SimpleGrid
                                    cols={1}
                                    spacing='md'
                                    breakpoints={fnBreakPointItems(stateIsShowDetails)}
                                >
                                    {stateData.filter(item => item.master_type === 'file').map((item, indexDT) => {
                                        return (
                                            <FileComponent
                                                key={indexDT}
                                                stateData={item}
                                                stateItemsSelected={stateItemsSelected}
                                                fnSelected={fnSelected}
                                                fnHoldItem={fnHoldItem}
                                                fnOnDoubleClick={fnOnDoubleClick}
                                            />
                                        )
                                    })}
                                </SimpleGrid>
                            }
                        </Box>
                    }
                </React.Fragment>
            }

            {orderingItem === utils.orderingItem.mixedWithFiles.value &&
                <React.Fragment>
                    {Array.isArray(stateData) && (stateData.length > 0) ?
                        <SimpleGrid
                            cols={1}
                            spacing='md'
                            breakpoints={fnBreakPointItems(stateIsShowDetails)}
                        >
                            {Object.keys(stateData).map((keyDT, indexDT) => {
                                return (
                                    <FileComponent
                                        key={indexDT}
                                        stateData={stateData[keyDT]}
                                        stateItemsSelected={stateItemsSelected}
                                        fnSelected={fnSelected}
                                        fnHoldItem={fnHoldItem}
                                        fnOnDoubleClick={fnOnDoubleClick}
                                    />
                                )
                            })}
                        </SimpleGrid>
                        :
                        <Box>
                            <NoData />
                        </Box>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default ItemsComponent;