import React from "react";
import { useLocalStorage } from '@mantine/hooks';

import { utils } from "../utils/globalVariables";

const OrderByContext = React.createContext({})

export const OrderByContextProvider = ({ children }) => {

    const [orderBy, setOrderBy] = useLocalStorage({
        key: utils.localStorageName.orderBy,
        defaultValue: utils.orderBy.name.value,
        getInitialValueInEffect: false
    });

    const handleSetOrderByContext = (param) => {
        setOrderBy(param);
    }

    return (
        <OrderByContext.Provider value={{ orderBy, handleSetOrderByContext }}>
            {children}
        </OrderByContext.Provider>
    )
};

export const useOrderByContext = () => React.useContext(OrderByContext);