import React from "react";
import { ActionIcon, useMantineTheme } from '@mantine/core';
import { IconArrowUp, IconArrowDown } from '@tabler/icons-react';

import { utils } from "../../utils/globalVariables";
import { useOrderTypeContext } from "../../context/orderTypeContext";

const OrderTypeSelector = ({ fnRemoveItemsSelected }) => {

    const theme = useMantineTheme();
    const { orderType, handleSetOrderTypeContext } = useOrderTypeContext();

    return (
        <React.Fragment>
            <ActionIcon
                variant='subtle'
                radius='xl'
                size='lg'
                sx={{
                    border: 0,
                    color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                    '&:hover': {
                        backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                    }
                }}
                title={'Sort direction'}

                onClick={() => {
                    fnRemoveItemsSelected();
                    handleSetOrderTypeContext(orderType === utils.orderType.asc.value ? utils.orderType.desc.value : utils.orderType.asc.value)
                }}
            >
                {orderType === utils.orderType.asc.value ?
                    <IconArrowUp
                        size={16}
                        strokeWidth={1.5}
                    />
                    :
                    <IconArrowDown
                        size={16}
                        strokeWidth={1.5}
                    />
                }
            </ActionIcon>
        </React.Fragment>
    )
}

export default OrderTypeSelector;