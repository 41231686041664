import React from 'react';
import { useNavigate } from "react-router-dom";
import { AppShell, Navbar, Header, ActionIcon, Aside, Text, MediaQuery, TextInput, useMantineTheme, Group, Box, ScrollArea, UnstyledButton, Avatar, Menu, NavLink, Drawer } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconSettings, IconUser, IconAlignLeft, IconSearch, IconPhoto, IconMessageCircle, IconTrash, IconArrowsLeftRight, IconPlus, IconFolderPlus, IconFolder, IconFile, IconFolders, IconUsers, IconClockHour4, IconStar, IconCloud } from '@tabler/icons-react';

import { utils, brand } from '../utils/globalVariables';
import BrandComponent from './BrandComponent';
import MenuList from './MenuList';

const MenuDrawer = () => {

    const theme = useMantineTheme();
    const navigate = useNavigate();

    const [drawer, setDrawer] = React.useState(false);

    return (
        <React.Fragment>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                <ActionIcon
                    variant='subtle'
                    radius='xl'
                    size='lg'
                    mr='md'
                    ml='md'
                    sx={{
                        border: 0,
                        color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2]
                    }}
                    title={'Menu'}
                    onClick={() => setDrawer(true)}
                >
                    <IconAlignLeft
                        size={22}
                        strokeWidth={1.5}
                    />
                </ActionIcon>
            </MediaQuery>

            <Drawer
                opened={drawer}
                onClose={() => setDrawer(false)}
                title={<BrandComponent />}
                size='xs'
                withCloseButton={false}

                sx={() => ({
                    '& .mantine-Paper-root.mantine-Drawer-content::-webkit-scrollbar': {
                        width: '8px',
                        height: '8px',
                    },

                    '& .mantine-Paper-root.mantine-Drawer-content::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent'
                    },

                    '& .mantine-Paper-root.mantine-Drawer-content::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[4] : theme.colors.dark[4],
                        borderRadius: '4px'
                    },

                    '& .mantine-Paper-root.mantine-Drawer-content::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[5] : theme.colors.dark[3],
                    },
                })}
            >
                <Box mt='md'>
                    <MenuList />
                </Box>
            </Drawer>
        </React.Fragment>
    )
}

export default MenuDrawer;