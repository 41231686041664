import React from "react";
import { ActionIcon, useMantineTheme, Menu, Text, Group } from '@mantine/core';
import { IconDotsVertical, IconCheck } from '@tabler/icons-react';

import { utils } from "../../utils/globalVariables";
import { useOrderingItemContext } from "../../context/orderingItemContext";

const OrderingItemSelector = ({ fnRemoveItemsSelected }) => {

    const theme = useMantineTheme();
    const { orderingItem, handleSetOrderingItemContext } = useOrderingItemContext();

    return (
        <React.Fragment>
            <Menu
                shadow="md"
                width={250}
            >
                <Menu.Target>
                    <ActionIcon
                        variant='subtle'
                        radius='xl'
                        size='lg'
                        sx={{
                            border: 0,
                            color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                            '&:hover': {
                                backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[6],
                            }
                        }}
                        title={'More sorting options'}

                        onClick={fnRemoveItemsSelected}
                    >
                        <IconDotsVertical
                            size={16}
                            strokeWidth={1.5}
                        />
                    </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Label>
                        Show Folders
                    </Menu.Label>

                    <Menu.Item
                        onClick={() => {
                            handleSetOrderingItemContext(utils.orderingItem.foldersOnTop.value);
                        }}
                    >
                        <Group
                            noWrap
                            sx={(tema) => ({
                                color: orderingItem === utils.orderingItem.mixedWithFiles.value ? 'transparent' : (tema.colorScheme === 'light' ? tema.colors.blue[7] : tema.colors.blue[5])
                            })}
                        >
                            <IconCheck
                                size={20}
                                strokeWidth={1.5}
                            />

                            <Text
                                sx={(tema) => ({
                                    color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.dark[0]
                                })}
                            >
                                {utils.orderingItem.foldersOnTop.caption}
                            </Text>
                        </Group>
                    </Menu.Item>

                    <Menu.Item
                        onClick={() => {
                            handleSetOrderingItemContext(utils.orderingItem.mixedWithFiles.value);
                        }}
                    >
                        <Group
                            noWrap
                            sx={(tema) => ({
                                color: orderingItem === utils.orderingItem.foldersOnTop.value ? 'transparent' : (tema.colorScheme === 'light' ? tema.colors.blue[7] : tema.colors.blue[5])
                            })}
                        >
                            <IconCheck
                                size={20}
                                strokeWidth={1.5}
                            />

                            <Text
                                sx={(tema) => ({
                                    color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.dark[0]
                                })}
                            >
                                {utils.orderingItem.mixedWithFiles.caption}
                            </Text>
                        </Group>
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </React.Fragment>
    )
}

export default OrderingItemSelector;