import React from "react";

import { utils } from "../../utils/globalVariables";

import { Modal, Group, Button, useMantineTheme } from '@mantine/core';
import { IconAlertCircle, IconCircleCheck, IconCircleX } from '@tabler/icons-react';

const ModalResponse = ({
    stateShow,
    fnHide,
    stateHideOnClickOutSide = true,
    stateType = utils.modalResponse.warning,
    stateContent = '',
    stateOverlayZIndex = null,
}) => {

    const theme = useMantineTheme();

    const sxZIndex = stateOverlayZIndex === null ? {} : { zIndex: stateOverlayZIndex }

    const response = () => {
        if (stateType === utils.modalResponse.danger) {
            return {
                icon: <IconCircleX
                    size={48}
                    strokeWidth={2}
                    color={theme.colors.red[7]}
                />,
                color: 'red'
            }
        } else if (stateType === utils.modalResponse.success) {
            return {
                icon: <IconCircleCheck
                    size={48}
                    strokeWidth={2}
                    color={theme.colors.teal[6]}
                />,
                color: 'teal'
            }
        } else {
            return {
                icon: <IconAlertCircle
                    size={48}
                    strokeWidth={2}
                    color={theme.colors.orange[5]}
                />,
                color: 'orange'
            }
        }
    }

    return (
        <Modal
            opened={stateShow}
            onClose={fnHide}
            withCloseButton={false}
            centered
            closeOnClickOutside={stateHideOnClickOutSide}
            // size='md'

            styles={() => ({
                overlay: {
                    ...sxZIndex
                }
            })}
        >
            <Group position="center">
                {response().icon}
            </Group>

            <Group position="center" mt='md'>
                {stateContent}
            </Group>

            <Group position="center" mt='md'>
                <Button
                    color={response().color}
                    size="xs"
                    onClick={fnHide}
                >
                    Close
                </Button>
            </Group>
        </Modal>
    )
}

export default ModalResponse;