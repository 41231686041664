import React from "react";
import { Navigate } from "react-router-dom";
import { useLoginContext } from "../context/loginContext";
import { role } from "../utils/globalVariables";
import { safetyDecodeJWT } from "../utils/globalFunctions";

const DosenRoute = ({ stateComponent }) => {

    const { loginContext } = useLoginContext();

    try {
        if (safetyDecodeJWT(loginContext, 'syswebapp', 'syswebappsl') === role.dosen.subLevel.dosen.slugSubLevel) {
            return stateComponent;
        }
        else {
            return <Navigate to='/' replace={true} />;
        }
    } catch (error) {
        return <Navigate to='/' replace={true} />;
    }
}

export default DosenRoute;