import React from "react";
import { Modal, ScrollArea, Box, Group, Button } from '@mantine/core';

const ModalWrapperWithFooterCloseButton = ({
    children,
    stateShow,
    fnHide,
    stateHideOnClickOutSide = true,
    stateTitle = '',
    stateSize = 'md',
    stateScrollArea = true,

    stateOverlayZIndex = null,
    stateContentSX = {},
    stateWithCloseButton = true,

    stateButtonCloseColor = 'blue',
    stateButtonCloseSize = 'md',
    stateButtonCloseVariant = 'default',
    stateButtonClosePx = 'sm',
    stateButtonCloseCaption = ''
}) => {
    const sxZIndex = stateOverlayZIndex === null ? {} : { zIndex: stateOverlayZIndex }

    return (
        <Modal.Root
            opened={stateShow}
            onClose={fnHide}
            closeOnClickOutside={stateHideOnClickOutSide}
            size={stateSize}
            centered

            styles={() => ({
                overlay: {
                    ...sxZIndex
                },
                content: { stateContentSX }
            })}
        >
            <Modal.Overlay />

            <Modal.Content
                scrollAreaComponent={stateScrollArea ? ScrollArea.Autosize : null}
            >
                <Modal.Header>
                    <Modal.Title sx={{ fontWeight: '500' }}>{stateTitle}</Modal.Title>
                    {stateWithCloseButton && <Modal.CloseButton />}
                </Modal.Header>

                <Modal.Body>
                    {children}
                </Modal.Body>

                <Modal.Header sx={{ bottom: 0 }}>
                    <Box sx={{ width: '100%' }}>
                        <Group position='right'>
                            <Button
                                color={stateButtonCloseColor}
                                onClick={fnHide}
                                size={stateButtonCloseSize}
                                variant={stateButtonCloseVariant}
                                px={stateButtonClosePx}
                            >
                                {stateButtonCloseCaption}
                            </Button>
                        </Group>
                    </Box>
                </Modal.Header>
            </Modal.Content>
        </Modal.Root>
    )
}

export default ModalWrapperWithFooterCloseButton;