import React from "react";
import axios from "axios";
import validator from 'validator';
import { notifications } from '@mantine/notifications';
import { TextInput } from '@mantine/core';

import { useLoginContext } from "../context/loginContext";
import { BE_API_ONE, utils } from "../utils/globalVariables";
import { safeValue } from "../utils/globalFunctions";

import ModalResponse from "../components/general/ModalResponse";
import ModalTakeAction from "../components/general/ModalTakeAction";

const FnRenameFolder = ({ stateShow, fnShow, stateData, fnSuccessRenameFolder }) => {

    const mountedRef = React.useRef(true);

    const { loginContext, handleSetLoginContext } = useLoginContext();

    const notificationId = React.useRef({
        errorSubmit: 'fnRenameFolder-1',
        successSubmit: 'fnRenameFolder-2',
    })

    const [data, setData] = React.useState({
        modalResponse: {
            show: false,
            type: utils.modalResponse.warning,
            hideOnClickOutSide: false,
            content: ''
        },

        submitLoading: false,
        value: '',
        valueError: ''
    });

    React.useEffect(() => {
        return () => {
            // mountedRef.current = false;
        }
    }, [])

    React.useEffect(() => {
        setData(d => ({ ...d, value: stateData.name }))
    }, [stateData.name])

    const handleSubmit = () => {
        setData(d => ({ ...d, valueError: '' }));

        if (validator.isEmpty(data.value, { ignore_whitespace: true })) {
            setData(d => ({ ...d, valueError: 'Field is required.' }))
        } else {
            setData(d => ({ ...d, submitLoading: true }))

            const formDT = new FormData();
            const formDTHeader = { 'Content-Type': 'application/json' }

            formDT.append('json_token', loginContext.syswebapp);
            formDT.append('value', data.value);
            formDT.append('item_id', stateData.id);

            axios({
                method: 'post',
                url: BE_API_ONE + '/rename_folder',
                data: formDT,
                headers: formDTHeader,
            }).then((response) => {
                if (!mountedRef.current) return null;

                console.log(response.data)

                setData(d => ({ ...d, submitLoading: false }));

                if (response.data.flag_status === 'isset') {
                    setData(d => ({
                        ...d,
                        value: ''
                    }))

                    fnSuccessRenameFolder();
                }

                if (response.data.flag_status === 'error') {
                    notifications.show({
                        id: notificationId.current.errorSubmit,
                        title: utils.notification.warning.caption,
                        message: response.data.pesan,
                        color: utils.notification.warning.color,
                        icon: utils.notification.warning.icon,
                        withBorder: true,
                        autoClose: true,
                    })
                }

                if (response.data.flag_status === 'no-folder') {
                    setData(d => ({
                        ...d,
                        modalResponse: {
                            ...d.modalResponse,
                            type: utils.modalResponse.warning,
                            hideOnClickOutSide: true,
                            content: 'Folder not found.',
                            show: true
                        }
                    }))
                }

                if (response.data.flag_status === 'invalid-json-token') {
                    handleSetLoginContext(null);
                }

                if (response.data.flag_status === 'user-inactive') {
                    handleSetLoginContext(null);
                }
            }).catch(error => {
                if (!mountedRef.current) return null;

                setData(d => ({ ...d, submitLoading: false }));
                fnShow(false);

                notifications.show({
                    id: notificationId.current.errorSubmit,
                    title: utils.notification.warning.caption,
                    message: error.message,
                    color: utils.notification.warning.color,
                    icon: utils.notification.warning.icon,
                    withBorder: true,
                    autoClose: true,
                })
            })
        }
    }

    return (
        <React.Fragment>
            <ModalTakeAction
                stateShow={stateShow}
                fnHide={() => fnShow(false)}

                stateHideOnClickOutSide={true}
                stateTitle={String('Rename Folder').toUpperCase()}
                // stateOverlayZIndex={201}

                stateButtonClosePx='md'

                stateButtonActionCaption='Rename'
                stateButtonActionPx='xs'
                stateButtonActionColor="blue"
                stateButtonActionLoading={data.submitLoading}
                stateButtonActionDisabled={!data.value}
                fnButtonAction={handleSubmit}
            >
                <TextInput
                    placeholder="Folder Name"
                    label="Folder Name"
                    withAsterisk={false}
                    radius="md"
                    value={safeValue(data.value, '')}
                    onChange={(e) => {
                        setData(d => ({ ...d, value: e.target.value }))
                    }}
                    error={data.valueError}
                />
            </ModalTakeAction>

            <ModalResponse
                stateShow={data.modalResponse.show}
                fnHide={() => setData(d => ({
                    ...d,
                    modalResponse: {
                        ...d.modalResponse,
                        show: false
                    }
                }))}

                stateHideOnClickOutSide={data.modalResponse.hideOnClickOutSide}
                stateType={data.modalResponse.type}
                stateContent={data.modalResponse.content}
            />
        </React.Fragment>
    )
}

export default React.memo(FnRenameFolder);