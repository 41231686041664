import React from "react";
import { utils } from "../../utils/globalVariables";
import { useOrderingItemContext } from "../../context/orderingItemContext";

const ItemOrderingTypeCaption = ({ stateData }) => {

    const { orderingItem } = useOrderingItemContext();

    return (
        <React.Fragment>
            {orderingItem === utils.orderingItem.foldersOnTop.value ? (stateData.some(obj => obj.master_type === 'folder') ? 'Folder' : 'Files') : 'Mixed with files'}
        </React.Fragment>
    )
}

export default ItemOrderingTypeCaption;