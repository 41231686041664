import { IconCheck, IconX, IconExclamationMark, IconDatabase, IconPoint, IconCode, IconDevices2, IconExchange } from '@tabler/icons-react';

const DOMAIN = 'veccani.com';
const PORT = ''; /* kosongkan jika tidak menggunakan port */
const SUB_DOMAIN = 'psy.dev.001'; /* kosongkan jika bukan sub domain */
const BACK_END = 'api';

// const DOMAIN = process.env.REACT_APP_HOST;
// const PORT = process.env.REACT_APP_PORT; /* kosongkan jika tidak menggunakan port */
// const SUB_DOMAIN = ''; /* kosongkan jika bukan sub domain */
// const BACK_END = 'psy';

export const DOMAIN_NAME = 'https://' + DOMAIN;
export const DOMAIN_NAME_HOST = DOMAIN_NAME + (PORT ? (':' + PORT) : '');
export const ROOT_BASE_URL = DOMAIN_NAME + (SUB_DOMAIN ? ('/' + SUB_DOMAIN) : '') + '/' + BACK_END;
export const DOMAIN_FE = SUB_DOMAIN ? (SUB_DOMAIN + '.' + DOMAIN) : DOMAIN_NAME_HOST;

// ! jangan menggunakan slash di akhir URL !
export const BE_API_SU_ONE = ROOT_BASE_URL + '/apisuone';
export const BE_API_AUTH = ROOT_BASE_URL + '/apiauth';
export const BE_API_ONE = ROOT_BASE_URL + '/apione';
export const BE_API_TWO = ROOT_BASE_URL + '/apitwo';

export const BE_API_DOCUMENT = ROOT_BASE_URL + '/apidocument';
// ! jangan menggunakan slash di akhir URL !

const SetIcon = ({ StateIcon, stateColor = 'gray' }) => {
    return <StateIcon
        size="1rem"
        stroke={1}
        style={{
            color: stateColor
        }}
    />;
}

export const role = {
    super_user: {
        caption: 'Super User',
        slugLevel: 'super_user',
        localStorageIdleTimeName: '356b50040c73df0iv0454a4589f66586_su',
        subLevel: {
            super_user: {
                caption: 'Super User',
                slugSubLevel: 'super_user',
                slugLink: 'su',
            }
        }
    },
    dosen: {
        caption: 'Dosen',
        slugLevel: 'dosen',
        localStorageIdleTimeName: 'lskmfc7f046544ca9f94df8af8cfc0ae_ow',
        subLevel: {
            dosen: {
                caption: 'Dosen',
                slugSubLevel: 'dosen',
                slugLink: 'ds',
            }
        }
    },
    ka_prodi: {
        slugLevel: 'ka_prodi',
        caption: 'K.A Prodi',
        localStorageIdleTimeName: 'asdfe1fd31554b1cbe311b2b0af09e58_kp',
        subLevel: {
            ka_prodi: {
                caption: 'K.A Prodi',
                slugSubLevel: 'ka_prodi',
                slugLink: 'kp',
            },
        }
    },
    tata_usaha: {
        slugLevel: 'tata_usaha',
        caption: 'Tata Usaha',
        localStorageIdleTimeName: 'fghnba41470d4ac4b7e1586b7ba6ba98_tu',
        subLevel: {
            tata_usaha_bag_1: {
                caption: 'Tata Usaha Bag 1',
                slugSubLevel: 'tata_usaha_bag_1',
                slugLink: 'tu1',
            },
            tata_usaha_bag_2: {
                caption: 'Tata Usaha Bag 2',
                slugSubLevel: 'tata_usaha_bag_2',
                slugLink: 'tu2',
            },
        }
    },
    dekan: {
        slugLevel: 'dekan',
        caption: 'Dekan',
        localStorageIdleTimeName: 'soijh02b16a14a89b1bc842f7e76a32c_dk',
        subLevel: {
            dekan: {
                caption: 'Dekan',
                slugSubLevel: 'dekan',
                slugLink: 'dk',
            },
        }
    },
}

const imageTypesAllowed = ["image/png", "image/jpg", "image/jpeg"];

export const brand = {
    initial: 'psy-maranatha-202311-nightowl',
    shortName: 'PSY',
    longName: 'PSY',
    appName: 'File Manager Psikologi Maranatha',
    tag: {
        one: 'Lorem Ipsum',
        two: 'Lorem Ipsum',
    }
}

export const utils = {
    showMqt: false,
    dataLimit: 10000,
    shrinkWhenScrollYAt: 20, /* delete? */
    flagAll: `all-${brand.initial}`,
    useIdleTimer: false,

    linkVerifikasiSlug: 'verification-link',
    linkVerifikasiSlugSU: 'verification-link-super-user',

    minCharUsername: 3,
    minCharPassword: 3,
    maxCharUsername: 12,
    maxCharPassword: 12,
    generateUsernameLength: 6,
    generatePasswordLength: 6,

    imageTypesAllowed: imageTypesAllowed,
    imageTypesAllowedExt: imageTypesAllowed.map(key => key.split('/')[1]),
    imageMaxSizeUploadFotoInMB: 50,
    oneBytesIs: 1048576,

    navbar: {
        width1: 200,
        width2: 250,
    },

    details: {
        tabDetails: {
            value: 'details',
            caption: 'Details'
        },
        tabActivity: {
            value: 'activity',
            caption: 'Activity'
        },
    },

    theme: {
        dark: {
            slug: 'dark',
            caption: 'Gelap',
            captionReverse: 'Terang',
            ambience: '#C14346',
            mainColor: '#F7F9FC',
            folderColor: '#f2f6fc'
        },
        light: {
            slug: 'light',
            caption: 'Terang',
            captionReverse: 'Gelap',
            ambience: '#C72438',
            mainColor: '#F7F9FC',
            folderColor: '#f2f6fc'
        },
    },
    fluid: {
        true: {
            slug: 'true',
            caption: 'Lebar',
            captionReverse: 'Fokus',
        },
        false: {
            slug: 'false',
            caption: 'Fokus',
            captionReverse: 'Lebar',
        },
        default: false,
    },
    displayMode: {
        card: {
            slug: 'card',
            caption: 'Card'
        },
        table: {
            slug: 'table',
            caption: 'Table'
        },
    },
    orderingItem: {
        foldersOnTop: {
            value: 'folders_on_top',
            caption: 'On Top'
        },
        mixedWithFiles: {
            value: 'mixed_with_files',
            caption: 'Mixed with files'
        },
    },
    orderBy: {
        name: {
            value: 'item_name',
            caption: 'Name'
        },
        // lastModified: {
        //     value: 'last_modified',
        //     caption: 'Last Modified'
        // },
    },
    orderType: {
        asc: {
            value: 'asc',
            caption: 'Ascending'
        },
        desc: {
            value: 'desc',
            caption: 'Descending'
        },
    },
    itemBreakpoints: [
        { minWidth: 'xl', cols: 6, spacing: 'md' },
        { minWidth: 'lg', cols: 4, spacing: 'md' },
        { minWidth: 'md', cols: 3, spacing: 'md' },
        { minWidth: 'sm', cols: 2, spacing: 'md' },
        { minWidth: 'xs', cols: 2, spacing: 'md' }
    ],
    localStorageName: {
        theme: `theme-app-${brand.initial}`,
        // fluid: `fluid-app-${brand.initial}`,
        login: `login-app-${brand.initial}`,
        loginSU: `login-su-app-${brand.initial}`,
        displayMode: `display-mode-app-${brand.initial}`,
        // navBarState: `navbar-state-app-${brand.initial}`,
        orderBy: `order-by-app-${brand.initial}`,
        orderType: `order-type-app-${brand.initial}`,
        orderingItem: `ordering-item-app-${brand.initial}`,
    },
    className: {},
    notification: {
        success: {
            caption: 'Success',
            color: 'green',
            icon: <IconCheck />
        },
        warning: {
            caption: 'Warning',
            color: 'orange',
            icon: <IconExclamationMark />
        },
        error: {
            caption: 'Error',
            color: 'red',
            icon: <IconX />
        }
    },
    modalResponse: {
        success: 'success',
        warning: 'warning',
        danger: 'danger'
    },

    sortType: {
        string: {
            slug: 'string'
        },
        integer: {
            slug: 'integer'
        },
        float: {
            slug: 'float'
        },
        date: {
            slug: 'date'
        },
    },
    priority: {
        3: {
            value: 3,
            caption: 'Low',
            colorBgLight: '#228BE6',
            colorBgDark: '#1971C2',
        },
        2: {
            value: 2,
            caption: 'Medium',
            colorBgLight: '#FF922B',
            colorBgDark: '#FD7E14',
        },
        1: {
            value: 1,
            caption: 'High',
            colorBgLight: '#F03E3E',
            colorBgDark: '#E03131',
        },
    },
    gender: {
        1: {
            value: 1,
            caption: 'Pria',
        },
        0: {
            value: 0,
            caption: 'Wanita',
        },
        2: {
            value: 2,
            caption: 'Ungendered',
        },
    },
}

export const menuList = {
    pengaturanAkun: {
        menuCaptionShort: 'Pengaturan Akun',
        menuCaptionLong: 'Pengaturan Akun',
        slug: 'pengaturan-akun',
        menuIcon: <SetIcon StateIcon={IconPoint} />,
        isShow: false
    },
    users: {
        menuCaptionShort: 'Kelola User',
        menuCaptionLong: 'Kelola User',
        slug: 'kelola-user',
        menuIcon: <SetIcon StateIcon={IconPoint} />,
        isShow: true
    },
    emailAplikasi: {
        menuCaptionShort: 'Kelola Email Aplikasi',
        menuCaptionLong: 'Kelola Email Aplikasi',
        slug: 'kelola-email-aplikasi',
        menuIcon: <SetIcon StateIcon={IconPoint} />,
        isShow: true
    },
    // proses: {
    //     menuCaptionShort: 'Proses',
    //     menuCaptionLong: 'Proses',
    //     slug: 'proses',
    //     menuIcon: <SetIcon StateIcon={IconDevices2} />,
    //     isShow: true
    // },
    project: {
        menuCaptionShort: 'Project',
        menuCaptionLong: 'Project',
        slug: 'project',
        menuIcon: <SetIcon StateIcon={IconDevices2} stateColor='green' />,
        isShow: true
    },
    master: {
        menuCaptionShort: 'Master Data',
        menuCaptionLong: 'Master Data',
        slug: 'master',
        menuIcon: <SetIcon StateIcon={IconDatabase} stateColor='#1c73c5' />,
        isShow: true
    },
    account: {
        menuCaptionShort: 'Account',
        menuCaptionLong: 'Account',
        slug: 'account',
        menuIcon: <SetIcon StateIcon={IconDatabase} stateColor='#1c73c5' />,
        isShow: false
    },
    // stockAdjustment: {
    //     menuCaptionShort: 'Penyesuaian Stok',
    //     menuCaptionLong: 'Penyesuaian Stok',
    //     slug: 'stock-adjustment',
    //     menuIcon: <SetIcon StateIcon={IconExchange} stateColor='#1c73c5' />,
    //     isShow: false
    // },
    dev: {
        menuCaptionShort: 'Dev',
        menuCaptionLong: 'Development',
        slug: 'dev',
        menuIcon: <SetIcon StateIcon={IconCode} stateColor='red' />,
        isShow: false
    },
    manage: {
        menuCaptionShort: 'Manage Data',
        menuCaptionLong: 'Manage Data',
        slug: 'manage',
        menuIcon: <SetIcon StateIcon={IconDatabase} stateColor='#1c73c5' />,
        isShow: true
    },
    // laporan: {
    //     menuCaptionShort: 'Laporan',
    //     menuCaptionLong: 'Laporan',
    //     slug: 'laporan',
    //     menuIcon: <SetIcon StateIcon={IconPoint} />,
    //     isShow: true
    // },
}

export const menuListSU = {
    subMenu: {
        one: {
            flag: `${menuList.manage.slug}-data-user`,
            name: 'Data User',
            icon: <SetIcon StateIcon={IconPoint} />,
            menu: menuList.manage.slug,
            tabs: {
                one: {
                    flag: `${menuList.manage.slug}-tab-data-user`,
                    name: 'Data User',
                },
                two: {
                    flag: `${menuList.manage.slug}-tab-superuser-utilities`,
                    name: 'Super User Utilitas',
                },
                three: {
                    flag: `${menuList.manage.slug}-tab-application-utilities`,
                    name: 'Aplikasi Utilitas',
                },
            }
        },
    }
}

export const menuListOW = {
    master: {
        ...menuList.master,
        ...{
            subMenu: {
                two: {
                    flag: `${menuList.master.slug}-customer`,
                    name: 'Customer',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.master.slug,
                    tabs: {}
                },
                brgKtgr: {
                    flag: `${menuList.master.slug}-barang-kategori`,
                    name: 'Kategori Barang',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.master.slug,
                    tabs: {}
                },
                formCekBrg: {
                    flag: `${menuList.master.slug}-form-cek-barang`,
                    name: 'Form Cek Barang',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.master.slug,
                    tabs: {}
                },
                stock: {
                    flag: `${menuList.master.slug}-stock`,
                    name: 'Stok Item',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.master.slug,
                    tabs: {
                        one: {
                            flag: `${menuList.master.slug}-tab-stock-item`,
                            name: 'Stok Item',
                        },
                        two: {
                            flag: `${menuList.master.slug}-tab-stock-category`,
                            name: 'Stok Kategori',
                        },
                        three: {
                            flag: `${menuList.master.slug}-tab-stock-category-sub`,
                            name: 'Stok Sub Kategori',
                        },
                    }
                },
                supplier: {
                    flag: `${menuList.master.slug}-supplier`,
                    name: 'Supplier',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.master.slug,
                    tabs: {}
                },
            }
        }
    },
    account: {
        ...menuList.account,
        ...{
            subMenu: {
                one: {
                    flag: `${menuList.account.slug}-account`,
                    name: 'Account',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.account.slug,
                    tabs: {
                        one: {
                            flag: `${menuList.account.slug}-tab-reset-email`,
                            name: 'Reset Email',
                        },
                        two: {
                            flag: `${menuList.account.slug}-tab-reset-username-password`,
                            name: 'Reset Username dan Password',
                        },
                    }
                }
            }
        }
    },
    dev: {
        ...menuList.dev,
        ...{
            subMenu: {
                one: {
                    flag: 'custom-components',
                    name: 'Custom Components',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.dev.slug,
                    tabs: {}
                },
                two: {
                    flag: 'insert-database',
                    name: 'Insert Database',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.dev.slug,
                    tabs: {}
                },
                three: {
                    flag: 'jwt',
                    name: 'JWT',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.dev.slug,
                    tabs: {}
                },
                four: {
                    flag: 'alur',
                    name: 'Alur',
                    icon: <SetIcon StateIcon={IconPoint} />,
                    menu: menuList.dev.slug,
                    tabs: {
                        one: {
                            flag: `${menuList.dev.slug}-tab-ketentuan-garansi`,
                            name: 'Garansi',
                        },
                    }
                },
            }
        }
    },
}