import React from 'react';
import { Text, Group } from '@mantine/core';
import { brand } from '../utils/globalVariables';
import logoHeader from '../assets/images/logo_header.png';

const BrandComponent = () => {
    return (
        <Group
            noWrap
            spacing='sm'
        >
            {/* <img
                src={logoHeader}
                alt='Logo'
                style={{
                    width: '22px',
                }}
            /> */}

            <Text
                fw='bold'
                fz='lg'
                tt='uppercase'
                lineClamp={1}

                variant="gradient"
                gradient={{ from: 'purple', to: 'blue', deg: 90 }}

                sx={{
                    fontFamily: 'Greycliff CF, sans-serif',
                    paddingTop: '3px',
                    letterSpacing: '2px'
                }}
            >
                {brand.shortName} DRIVE
            </Text>
        </Group>
    )
}

export default BrandComponent;