import React from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import validator from 'validator';
import { Paper, createStyles, TextInput, PasswordInput, Input, Button, Title, Text, Anchor, rem, Group } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { useLoginContext } from "../../context/loginContext";
import { useLoginContextSU } from "../../context/loginContextSU";

import { utils, brand, BE_API_AUTH } from "../../utils/globalVariables";
import { regexNoSpace, safetyDecodeJWT, getPropInSubLevelJWT } from "../../utils/globalFunctions";

import ModalResponse from "../../components/general/ModalResponse";
import imageLoginPage from "../../assets/images/login_page_6.jpg";

// import ForgotPasswordFirstStep from "./ForgotPasswordFirstStep";

const useStyles = createStyles((theme) => ({
    wrapper: {
        minHeight: '100vh',
        backgroundSize: 'cover',
        backgroundImage: `url(${imageLoginPage})`,
    },

    form: {
        borderRight: `${rem(1)} solid ${theme.colorScheme === utils.theme.dark.slug ? theme.colors.dark[8] : theme.colors.gray[3]}`,
        minHeight: '100vh',
        maxWidth: rem(400),
        paddingTop: rem(80),
        backgroundColor: theme.colorScheme === utils.theme.dark.slug ? theme.colors.dark[8] : '#fff',

        label: {
            color: theme.colorScheme === utils.theme.dark.slug ? theme.colors.gray[3] : theme.colors.gray[7]
        },

        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },

    title: {
        color: theme.colorScheme === utils.theme.dark.slug ? theme.colors.gray[3] : theme.colors.gray[7],
    },

    anchorForgotPassword: {
        color: theme.colorScheme === utils.theme.dark.slug ? theme.colors.gray[3] : theme.colors.gray[7],
        textDecoration: 'none',

        '&:hover': {
            textDecoration: 'none',
        }
    },

    buttonSignIn: {
        border: 0,
        '&[data-disabled=true]': {
            color: theme.colors.gray[6]
        }
    }
}));

const LoginIndex = () => {

    const mountedRef = React.useRef(true);
    const { classes } = useStyles();
    const navigate = useNavigate();

    const { handleSetLoginContext } = useLoginContext();
    const { handleSetLoginContextSU } = useLoginContextSU();

    const notificationId = React.useRef({
        loginSubmit: 'LoginIndex-1',
    })

    const [data, setData] = React.useState({
        userUserName: {
            id: 'loginPageUserUserName',
            label: 'Username',
            withAsterisk: false,
            placeholder: 'Username',
            value: 'dosen1',
            description: 'Username : dosen1',
            error: ''
        },

        userPassword: {
            id: 'loginPageUserPassword',
            label: 'Password',
            withAsterisk: false,
            placeholder: 'Your Password',
            value: '123',
            description: 'Password : 123',
            error: ''
        },

        submitLoading: false,

        lupaPasswordModalShow: false,

        forgotPassword: {
            modal: false,
        },

        modalResponse: {
            show: false,
            type: utils.modalResponse.warning,
            hideOnClickOutSide: false,
            content: ''
        }
    });

    React.useEffect(() => {
        document.title = 'Sign In ' + brand.appName;

        return () => {
            // mountedRef.current = false;
        };
    }, []);

    const handleSubmit = () => {
        if (JSON.parse(window.localStorage.getItem(utils.localStorageName.loginSU))) {
            setData(d => ({
                ...d,
                modalResponse: {
                    ...d.modalResponse,
                    type: utils.modalResponse.warning,
                    hideOnClickOutSide: true,
                    content: <React.Fragment>
                        <Text size='sm' color='dimmed'>
                            Anda masih sign in menggunakan akun {safetyDecodeJWT(JSON.parse(window.localStorage.getItem(utils.localStorageName.loginSU)), 'syswebapp', 'syswebappn')} - {getPropInSubLevelJWT(JSON.parse(window.localStorage.getItem(utils.localStorageName.loginSU)), 'caption', '')}. Sign out terlebih dahulu pada akun tersebut.
                        </Text>
                    </React.Fragment>,
                    show: true
                }
            }))
        } else {
            handleSetLoginContextSU(null);

            notifications.hide(notificationId.current.loginSubmit);

            setData(d => ({
                ...d,
                userUserName: { ...d.userUserName, error: '' },
                userPassword: { ...d.userPassword, error: '' },
            }));

            if (validator.isEmpty(data.userUserName.value, { ignore_whitespace: true })) {
                setData(d => ({ ...d, userUserName: { ...d.userUserName, error: ' ', } }));
            } else if (validator.isEmpty(data.userPassword.value, { ignore_whitespace: true })) {
                setData(d => ({ ...d, userPassword: { ...d.userPassword, error: ' ', } }));
            } else {
                setData(d => ({ ...d, submitLoading: true }));

                const formDT = new FormData();
                const formDTHeader = { 'Content-Type': 'application/json' }

                formDT.append('username', data.userUserName.value);
                formDT.append('password', data.userPassword.value);

                axios({
                    method: 'post',
                    url: BE_API_AUTH + '/authentication_signin',
                    data: formDT,
                    headers: formDTHeader,
                }).then((response) => {
                    if (!mountedRef.current) return null;

                    setData(d => ({ ...d, submitLoading: false }));

                    if (response.data.flag_status === 'isset') {
                        handleSetLoginContext(response.data.data);

                        // navigate ini kyk nya ga di eksekusi
                        navigate(`/`, { replace: true });
                    }

                    if (response.data.flag_status === 'username-tidak-ada') {
                        notifications.show({
                            id: notificationId.current.loginSubmit,
                            title: utils.notification.warning.caption,
                            message: 'Akun tidak terdaftar',
                            color: utils.notification.warning.color,
                            icon: utils.notification.warning.icon,
                            withBorder: true,
                            autoClose: true,
                        })
                    }

                    if (response.data.flag_status === 'wrong-password') {
                        notifications.show({
                            id: notificationId.current.loginSubmit,
                            title: utils.notification.warning.caption,
                            message: 'Password salah !',
                            color: utils.notification.warning.color,
                            icon: utils.notification.warning.icon,
                            withBorder: true,
                            autoClose: true,
                        })
                    }
                }).catch(error => {
                    if (!mountedRef.current) return null;

                    setData(d => ({ ...d, submitLoading: false }));

                    notifications.show({
                        id: notificationId.current.loginSubmit,
                        title: utils.notification.warning.caption,
                        message: error.message,
                        color: utils.notification.warning.color,
                        icon: utils.notification.warning.icon,
                        withBorder: true,
                        autoClose: true,
                    })
                })
            }
        }
    }

    return (
        <React.Fragment>
            <div className={`${classes.wrapper}`}>
                <Paper className={classes.form} radius={0} p={30}>
                    <Title order={3} className={classes.title} ta="center" mt="md" mb={50}>
                        <Text>File Manager</Text>
                        <Text size='md'>Psikologi Maranatha</Text>
                    </Title>

                    <Input.Wrapper
                        id={data.userUserName.id}
                        size="sm"
                        withAsterisk={data.userUserName.withAsterisk}
                        label={data.userUserName.label}
                        description={data.userUserName.description}
                    >
                        <TextInput
                            id={data.userUserName.id}
                            variant="filled"
                            placeholder={data.userUserName.placeholder}

                            value={data.userUserName.value}
                            onChange={(e) => setData(d => ({
                                ...d,
                                userUserName: {
                                    ...d.userUserName,
                                    value: regexNoSpace(e.target.value)
                                }
                            }))}
                            onKeyUp={(e) => e.key === 'Enter' && !data.submitLoading && handleSubmit()}

                            error={data.userUserName.error}
                        />
                    </Input.Wrapper>

                    <Input.Wrapper
                        id={data.userPassword.id}
                        size="sm"
                        mt="md"
                        withAsterisk={data.userPassword.withAsterisk}
                        label={data.userPassword.label}
                        description={data.userPassword.description}
                    >
                        <PasswordInput
                            id={data.userPassword.id}
                            variant="filled"
                            placeholder={data.userPassword.placeholder}

                            value={data.userPassword.value}
                            onChange={(e) => {
                                setData(d => ({
                                    ...d,
                                    userPassword: {
                                        ...d.userPassword,
                                        value: regexNoSpace(e.target.value)
                                    }
                                }))
                            }}
                            onKeyUp={(e) => e.key === 'Enter' && !data.submitLoading && handleSubmit()}

                            error={data.userPassword.error}
                        />
                    </Input.Wrapper>

                    <Group
                        position="apart"
                        mt='lg'
                    >
                        <Text size='sm'>
                            <Anchor
                                className={classes.anchorForgotPassword}
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setData(d => ({ ...d, forgotPassword: { ...d.forgotPassword, modal: true } }))
                                }}
                            >
                                Forgot password ?
                            </Anchor>
                        </Text>

                        <Button
                            size="sm"
                            className={classes.buttonSignIn}
                            px='1.5rem'
                            variant='default'
                            disabled={data.submitLoading}

                            onClick={handleSubmit}
                        >
                            {data.submitLoading ? 'Sign In' : 'Sign In'}
                        </Button>
                    </Group>
                </Paper>
            </div>

            {/* <ForgotPasswordFirstStep
                stateShow={data.forgotPassword.modal}
                fnHide={() => setData(d => ({ ...d, forgotPassword: { ...d.forgotPassword, modal: false } }))}
            /> */}

            <ModalResponse
                stateShow={data.modalResponse.show}
                fnHide={() => setData(d => ({
                    ...d,
                    modalResponse: {
                        ...d.modalResponse,
                        show: false
                    }
                }))}

                stateHideOnClickOutSide={data.modalResponse.hideOnClickOutSide}
                stateType={data.modalResponse.type}
                stateContent={data.modalResponse.content}
            />
        </React.Fragment>
    )
}

export default LoginIndex;