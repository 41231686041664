import React from "react";
import { ActionIcon, Text, useMantineTheme, Group, Box, ScrollArea, Menu, Paper, Tooltip } from '@mantine/core';
import { IconInfoCircle, IconDots, IconChevronRight, IconChevronDown, IconFile, IconFolder, IconFolderFilled, IconUserCircle, IconX, IconDotsVertical, IconUser, IconDownload, IconPencil, IconUserPlus, IconLink, IconSquareArrowRight, IconStar, IconTrash, IconFileFilled, IconFiles, IconFolderShare } from '@tabler/icons-react';

import { utils } from '../../utils/globalVariables';

let timer;

const FileComponent = ({ stateData, stateItemsSelected, fnSelected, fnHoldItem, fnOnDoubleClick }) => {

    const theme = useMantineTheme();

    const stateItemsSelectedArray = stateItemsSelected.map(item => item.item_id);
    const isIncludes = stateItemsSelectedArray.includes(stateData.item_id);
    const selectedItemMoreThanOne = Array.isArray(stateItemsSelected) && (stateItemsSelected.length > 1);

    return (
        <Paper
            radius="md"
            p=".35rem"
            sx={(tema) => ({
                backgroundColor: tema.colorScheme === 'light' ? (isIncludes ? tema.colors.blue[1] : utils.theme.light.folderColor) : (isIncludes ? tema.colors.cyan[9] : tema.colors.dark[6]),
                '&:hover': {
                    backgroundColor: tema.colorScheme === 'light' ? (isIncludes ? tema.colors.blue[1] : tema.colors.gray[2]) : (isIncludes ? tema.colors.cyan[9] : tema.colors.dark[5])
                }
            })}

            onClick={(e) => {
                e.stopPropagation();
                fnSelected(stateData, e);
            }}

            onDoubleClick={stateData.master_type === 'folder' ?
                () => {
                    fnOnDoubleClick(stateData)
                }
                :
                () => { }
            }

            onTouchStart={(e) => {
                timer = setTimeout(() => {
                    // Logika untuk menangani "hold" di sini
                    e.stopPropagation();
                    fnHoldItem(stateData);
                }, 600); // Ubah sesuai keinginan durasi hold
            }}

            onTouchEnd={() => {
                clearTimeout(timer);
            }}
        >
            <Group
                noWrap
                spacing='.5rem'
                sx={(tema) => ({
                    cursor: 'default',
                    color: tema.colorScheme === 'light' ? tema.colors.gray[7] : (isIncludes ? tema.colors.gray[3] : tema.colors.dark[2])
                })}

                p='.35rem'
                mb='.2rem'
            >
                {stateData.master_type === 'folder' ?
                    <IconFolderFilled
                        size={24}
                        strokeWidth={1}
                    />
                    :
                    <IconFileFilled
                        size={24}
                        strokeWidth={1}
                    />
                }

                <Tooltip
                    label={stateData.item_name}
                    multiline
                    openDelay={600}
                    styles={(tema) => ({
                        tooltip: {
                            wordBreak: 'break-all'
                        }
                    })}
                >
                    <Text
                        size='.85rem'
                        fw='500'
                        lineClamp={1}
                        sx={(tema) => ({
                            // flex: 1,
                            width: '100%',
                            color: tema.colorScheme === 'light' ? tema.colors.gray[8] : (isIncludes ? tema.colors.gray[3] : tema.colors.dark[1]),
                            userSelect: 'none',
                            wordBreak: 'break-all'
                        })}
                    >
                        {stateData.item_name}
                    </Text>
                </Tooltip>

                <Menu
                    shadow="md"
                    width={250}
                >
                    <Menu.Target>
                        <ActionIcon
                            variant='subtle'
                            radius='xl'
                            size='sm'
                            sx={{
                                border: 0,
                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.gray[2],
                                '&:hover': {
                                    backgroundColor: theme.colorScheme === 'light' ? (isIncludes ? theme.colors.cyan[0] : theme.colors.gray[3]) : (isIncludes ? theme.colors.cyan[8] : theme.colors.dark[4]),
                                },
                                '&[data-disabled=true]': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                            title={'More actions'}
                            disabled={selectedItemMoreThanOne}
                        >
                            <IconDotsVertical
                                size={16}
                                strokeWidth={1.5}
                            />
                        </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <ScrollArea.Autosize
                            sx={(tema) => ({
                                // height: '100%',
                                maxHeight: 200
                            })}
                        >
                            <Menu.Item>
                                <Group noWrap>
                                    <IconDownload size={18} strokeWidth={1.5} />
                                    <Text>Download</Text>
                                </Group>
                            </Menu.Item>

                            <Menu.Item>
                                <Group noWrap>
                                    <IconPencil size={18} strokeWidth={1.5} />
                                    <Text>Rename</Text>
                                </Group>
                            </Menu.Item>

                            <Menu.Item>
                                <Group noWrap>
                                    <IconFiles size={18} strokeWidth={1.5} />
                                    <Text>Make a copy</Text>
                                </Group>
                            </Menu.Item>

                            <Menu.Divider />

                            <Menu.Item>
                                <Group noWrap>
                                    <IconUserPlus size={18} strokeWidth={1.5} />
                                    <Text>Share</Text>
                                </Group>
                            </Menu.Item>

                            <Menu.Item>
                                <Group noWrap>
                                    <IconLink size={18} strokeWidth={1.5} />
                                    <Text>Copy link</Text>
                                </Group>
                            </Menu.Item>

                            <Menu.Divider />

                            <Menu.Item>
                                <Group noWrap>
                                    <IconFolderShare size={18} strokeWidth={1.5} />
                                    <Text>Move</Text>
                                </Group>
                            </Menu.Item>

                            <Menu.Item>
                                <Group noWrap>
                                    <IconStar size={18} strokeWidth={1.5} />
                                    <Text>Add to starred</Text>
                                </Group>
                            </Menu.Item>

                            <Menu.Item>
                                <Group noWrap>
                                    <IconInfoCircle size={18} strokeWidth={1.5} />
                                    <Text>Details</Text>
                                </Group>
                            </Menu.Item>

                            <Menu.Divider />

                            <Menu.Item>
                                <Group noWrap>
                                    <IconTrash size={18} strokeWidth={1.5} />
                                    <Text>Move to trash</Text>
                                </Group>
                            </Menu.Item>
                        </ScrollArea.Autosize>
                    </Menu.Dropdown>
                </Menu>
            </Group>

            <Paper
                p='sm'
                sx={(tema) => ({
                    height: '12rem',
                    minHeight: '12rem',
                    maxHeight: '12rem',

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    color: tema.colorScheme === 'light' ? tema.colors.blue[4] : tema.colors.blue[7]
                })}
            >
                {stateData.master_type === 'folder' ?
                    <IconFolderFilled size={42} strokeWidth={1.5} />
                    :
                    <IconFileFilled size={42} strokeWidth={1.5} />
                }
            </Paper>
        </Paper>
    )
}

export default FileComponent;