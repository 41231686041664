import React from "react";

import { utils } from "../utils/globalVariables";
import { getLocalStorage, setLocalStorage } from "../utils/globalFunctions";

const LoginContext = React.createContext({})

export const LoginContextProvider = ({ children }) => {

    const getDefaultData = () => {
        const defaultData = getLocalStorage(utils.localStorageName.login);
        const setData = defaultData ? defaultData : null;
        return setData ? JSON.parse(setData) : null;
    }

    const [loginContext, setLoginContext] = React.useState(getDefaultData());

    const handleSetLoginContext = (param) => {
        setLocalStorage(utils.localStorageName.login, param ? JSON.stringify(param) : null);

        setLoginContext(param);
    }

    return (
        <LoginContext.Provider value={{ loginContext, setLoginContext, handleSetLoginContext }}>
            {children}
        </LoginContext.Provider>
    )
};

export const useLoginContext = () => React.useContext(LoginContext);