import React from "react";
import { useLocalStorage } from '@mantine/hooks';

import { utils } from "../utils/globalVariables";

const OrderTypeContext = React.createContext({})

export const OrderTypeContextProvider = ({ children }) => {

    const [orderType, setOrderType] = useLocalStorage({
        key: utils.localStorageName.orderType,
        defaultValue: utils.orderType.asc.value,
        getInitialValueInEffect: false
    });

    const handleSetOrderTypeContext = (param) => {
        setOrderType(param);
    }

    return (
        <OrderTypeContext.Provider value={{ orderType, handleSetOrderTypeContext }}>
            {children}
        </OrderTypeContext.Provider>
    )
};

export const useOrderTypeContext = () => React.useContext(OrderTypeContext);