import { decodeToken } from "react-jwt";
// import { useMantineTheme } from '@mantine/core';
import validator from 'validator';
import { utils, role } from "./globalVariables";
import { IconUser, IconUsers, IconBuildingBank } from '@tabler/icons-react';

const constDefaultValue = '';

// START DATE
const arrBulanId = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
const arrBulanEng = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const arrShortBulanId = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agt", "Sep", "Okt", "Nov", "Des"];
const arrShortBulanEng = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const arrHariId = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
const arrHariEng = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const isValidIsoDate = (stateDate) => {
    if (stateDate instanceof Date && !isNaN(stateDate.valueOf())) { return true; }
    else { return false; }
}

export const twoDigits = (prm) => {
    const leng = prm.toString().length;
    return leng < 2 ? '0' + prm : prm;
}

export const isoDateGetDayNumber = (stateDate) => {
    if (!isValidIsoDate(stateDate)) { return -1; }
    const day = stateDate.getDay();
    return Number(day);
}

export const isoDateToY = (stateDate, stateDefaultValue) => {
    let resDefVal = constDefaultValue;

    if (stateDefaultValue !== undefined) { resDefVal = stateDefaultValue; }

    if (!isValidIsoDate(stateDate)) { return resDefVal; }
    const varDate = new Date(Date.parse(stateDate));
    return varDate.getFullYear();
}

export const isoDateToHumanDate = ({
    stateDate,
    stateLang = 'id',
    stateIsShort = false,
    stateDay = false,
    stateDefaultValue,
    stateDateType = 'day' /* day | month */
}) => {
    let resDefVal = constDefaultValue;
    let lang = stateLang === 'id' ? (stateIsShort ? arrShortBulanId : arrBulanId) : (stateIsShort ? arrShortBulanEng : arrBulanEng);

    if (stateDefaultValue !== undefined) { resDefVal = stateDefaultValue; }

    if (!isValidIsoDate(stateDate)) { return resDefVal; }
    const varDate = new Date(Date.parse(stateDate));
    let day = stateDay ? ((stateLang === 'id' ? arrHariId[Number(varDate.getDay())] : arrHariEng[Number(varDate.getDay())]) + ', ') : '';

    let res = '';

    if (stateDateType === 'day') {
        res = day + varDate.getDate() + ' ' + lang[varDate.getMonth()] + ' ' + varDate.getFullYear();
    } else {
        res = lang[varDate.getMonth()] + ' ' + varDate.getFullYear();
    }

    return res;
}

export const isoDateToSqlDate = (stateDate, stateDefaultValue) => {
    let resDefVal = constDefaultValue;

    if (stateDefaultValue !== undefined) { resDefVal = stateDefaultValue; }

    if (!isValidIsoDate(stateDate)) { return resDefVal; }
    const varDate = new Date(Date.parse(stateDate));
    return varDate.getFullYear() + '-' + twoDigits(varDate.getMonth() + 1) + '-' + twoDigits(varDate.getDate())
}

export const isoDateToSqlDateTime = (stateDate, stateDefaultValue) => {
    let resDefVal = constDefaultValue;

    if (stateDefaultValue !== undefined) { resDefVal = stateDefaultValue; }

    if (!isValidIsoDate(stateDate)) { return resDefVal; }
    const varDate = new Date(Date.parse(stateDate));
    return varDate.getFullYear() + '-' + twoDigits(varDate.getMonth() + 1) + '-' + twoDigits(varDate.getDate()) + ' ' + twoDigits(varDate.getHours()) + ':' + twoDigits(varDate.getMinutes()) + ':' + twoDigits(varDate.getSeconds())
}

export const isoDateTimeBinder = (stateDate, stateHour = '00', stateMinute = '00', stateSecond = '00', stateDefaultValue = '') => {
    if (!isValidIsoDate(stateDate)) { return stateDefaultValue; }

    try {
        const varDate = new Date(Date.parse(stateDate));

        const res = varDate.getFullYear() + '-' + twoDigits(varDate.getMonth() + 1) + '-' + twoDigits(varDate.getDate()) + ' ' + twoDigits(stateHour) + ':' + twoDigits(stateMinute) + ':' + twoDigits(stateSecond);

        if (!isValidIsoDate(new Date(Date.parse(res)))) {
            return stateDefaultValue;
        }

        return new Date(Date.parse(res));
    } catch (error) {
        return stateDefaultValue;
    }
}

export const isoDateGetFraction = (stateDate, stateFraction = 'hour', stateIsTwoDigits = true, stateDefaultValue = '') => {
    if (!isValidIsoDate(stateDate)) { return stateDefaultValue; }

    const varDate = new Date(Date.parse(stateDate));

    if (stateFraction === 'year') {
        return varDate.getFullYear();
    } else if (stateFraction === 'month') {
        return stateIsTwoDigits ? twoDigits(varDate.getMonth() + 1) : varDate.getMonth() + 1;
    } else if (stateFraction === 'date') {
        return stateIsTwoDigits ? twoDigits(varDate.getDate()) : varDate.getDate();
    } else if (stateFraction === 'hour') {
        return stateIsTwoDigits ? twoDigits(varDate.getHours()) : varDate.getHours();
    } else if (stateFraction === 'minute') {
        return stateIsTwoDigits ? twoDigits(varDate.getMinutes()) : varDate.getMinutes();
    } else if (stateFraction === 'second') {
        return stateIsTwoDigits ? twoDigits(varDate.getSeconds()) : varDate.getSeconds();
    } else {
        return stateDefaultValue;
    }
}

export const sqlDateToHumanDate = ({
    stateDate,
    stateWithTime = false,
    stateLang = 'id',
    stateIsShort = false,
    stateDay = false,
    stateDefaultValue,
    stateDateType = 'day' /* day | month */,
    stateWithSecond = true
}) => {
    let resDefVal = constDefaultValue;
    let lang = stateLang === 'id' ? (stateIsShort ? arrShortBulanId : arrBulanId) : (stateIsShort ? arrShortBulanEng : arrBulanEng);

    if (stateDefaultValue !== undefined) { resDefVal = stateDefaultValue; }

    if (!isValidIsoDate(new Date(Date.parse(stateDate)))) { return resDefVal; }
    const varDate = new Date(Date.parse(stateDate));
    let day = stateDay ? ((stateLang === 'id' ? arrHariId[Number(varDate.getDay())] : arrHariEng[Number(varDate.getDay())]) + ', ') : '';

    try {
        const splitting = stateDate.split('-');
        const monthNumber = Number(splitting[1]) - 1;
        const month = monthNumber < 0 ? '' : lang[monthNumber];

        const tanggal = splitting[2].split(' ');
        let jam = '';

        if (stateWithTime) {
            if (tanggal[1]) {
                const jamSplited = tanggal[1].split(':');
                jam = jamSplited[0] + ':' + jamSplited[1] + (stateWithSecond ? (':' + jamSplited[1]) : '')
            }
        }

        // const jam = stateWithTime ? (tanggal[1] === undefined ? '' : tanggal[1]) : '';

        let res = '';

        if (stateDateType === 'day') {
            res = day + tanggal[0] + ' ' + month + ' ' + splitting[0] + ' ' + jam;
        } else {
            res = month + ' ' + splitting[0];
        }

        return res;
    } catch (error) {
        return resDefVal;
    }
}

export const sqlDateToIsoDate = (stateDate, stateDefaultValue = constDefaultValue) => {

    if (!isValidIsoDate(new Date(Date.parse(stateDate)))) {
        return stateDefaultValue;
    }

    return new Date(Date.parse(stateDate));
}

// END DATE


// START LOCAL STORAGE
export const setLocalStorage = (stateName, stateValue) => {
    return localStorage.setItem(stateName, stateValue);
}

export const getLocalStorage = (stateName) => {
    return localStorage.getItem(stateName);
}

export const removeLocalStorage = (stateName) => {
    return localStorage.removeItem(stateName);
}
// END LOCAL STORAGE


// START REGEX
export const regexNumberOnly = (stateValue) => {
    const n = String(stateValue);
    const result = n.replace(/\D/g, "");

    return result;
}

export const regexDecimal = (stateValue) => {
    function clean(string) {
        return string.replace(/[^0-9]/g, "");
    }

    var value = stateValue;
    var pos = value.indexOf(".");
    var result;
    if (pos !== -1) {
        var part1 = value.substring(0, pos);
        var part2 = value.substring(pos + 1);
        result = clean(part1) + "." + clean(part2);
    } else {
        result = clean(value);
    }

    return result;
}

export const regexNoSpace = (stateData) => {
    let res = '';

    if (String(stateData)) {
        res = String(stateData).replace(/\s/g, '');
    }

    return res;
}

export const regexNoStriped = (stateData) => {
    let res = '';

    if (String(stateData)) {
        res = String(stateData).replace(/-/g, '');
    }

    return res;
}

export const regexLimitChar = (stateData, stateLimit) => {
    let res = '';

    if (String(stateData)) {
        res = stateData.substring(0, stateLimit);
    }

    return res;
}

export const regex0to29 = ({ stateData = '', stateCanEmpty = true }) => {
    if (stateCanEmpty === false) {
        const regx = /^(0|[1-9]|[12]\d|29)$/;
        return regx.test(stateData);
    } else {
        const regx = /^(?:2[0-9]|[01]?[0-9]?)?$/;
        return regx.test(stateData);
    }
}

export const regexPhoneNumber = (prm) => {
    // https://www.huzefril.com/posts/regex/regex-nomor-telepon/
    // https://masputih.com/2015/11/belajar-regular-expression
    // https://agung-setiawan.com/fundamental-dari-regular-expression/
    // https://id.wikipedia.org/wiki/Daftar_kode_telepon_di_Indonesia

    const str = prm.replace(/[^0-9]/g, "");

    const hp = new RegExp(/^(\+62|62|08)\d{9,11}$/).test(str);
    const pstn = new RegExp(/^(\+62|62|0)([2-7]|9)\d(\d)?[2-9](\d){6,7}$/).test(str);

    if (hp || pstn) {
        return str.replace(/^(\+62|62)/, "0");
    } else {
        return false;
    }
}
// END REGEX


// START OBJECT
export const safetyDecodeJWT = (stateObject, stateParam1, stateParam2) => {
    try {
        return decodeToken(stateObject[stateParam1])[stateParam2];
    } catch (error) {
        return null;
    }
}

export const getPropInSubLevelJWT = (stateLoginContext, stateProp, stateDefaultValue = constDefaultValue) => {
    let res = stateDefaultValue;
    let shouldExitLoop = false;

    try {
        Object.values(role).forEach(valRole => {
            if (shouldExitLoop) { return }

            Object.values(valRole.subLevel).forEach(valRoleSL => {
                if (shouldExitLoop) { return }

                if (valRoleSL.slugSubLevel === safetyDecodeJWT(stateLoginContext, 'syswebapp', 'syswebappsl')) {
                    res = valRoleSL[stateProp];
                    shouldExitLoop = true;
                    return;
                }
            })
        })

        res = res === undefined ? stateDefaultValue : res;
    } catch (error) {
        res = stateDefaultValue;
    }

    return res;
}

export const getPropInSubLevelRole = (stateValue, stateProp, stateDefaultValue = constDefaultValue) => {
    let res = stateDefaultValue;
    let shouldExitLoop = false;

    try {
        Object.values(role).forEach(valRole => {
            if (shouldExitLoop) { return }

            Object.values(valRole.subLevel).forEach(valRoleSL => {
                if (shouldExitLoop) { return }

                if (valRoleSL.slugSubLevel === stateValue) {
                    res = valRoleSL[stateProp];
                    shouldExitLoop = true;
                    return;
                }
            })
        })

        res = res === undefined ? stateDefaultValue : res;
    } catch (error) {
        res = stateDefaultValue;
    }

    return res;
}

export const getPropInLevelRole = (stateValue, stateProp, stateDefaultValue = constDefaultValue) => {
    let res = stateDefaultValue;
    let shouldExitLoop = false;

    try {
        Object.values(role).forEach(valRole => {
            if (shouldExitLoop) { return }

            if (valRole.slugLevel === stateValue) {
                res = valRole[stateProp];
                shouldExitLoop = true;
                return;
            }
        })

        res = res === undefined ? stateDefaultValue : res;
    } catch (error) {
        res = stateDefaultValue;
    }

    return res;
}

export const getObjectParam1 = (stateObject, stateParam1, stateDefaultValue = constDefaultValue) => {
    try {
        return stateObject[stateParam1];
    } catch (error) {
        return stateDefaultValue;
    }
}

export const getObjectParam2 = (stateObject, stateParam1, stateParam2, stateDefaultValue = constDefaultValue) => {
    try {
        return stateObject[stateParam1][stateParam2];
    } catch (error) {
        return stateDefaultValue;
    }
}

export const getObjectParam3 = (stateObject, stateParam1, stateParam2, stateParam3, stateDefaultValue = constDefaultValue) => {
    try {
        return stateObject[stateParam1][stateParam2][stateParam3];
    } catch (error) {
        return stateDefaultValue;
    }
}

export const getObjectParam4 = (stateObject, stateParam1, stateParam2, stateParam3, stateParam4, stateDefaultValue = constDefaultValue) => {
    try {
        return stateObject[stateParam1][stateParam2][stateParam3][stateParam4];
    } catch (error) {
        return stateDefaultValue;
    }
}
// END OBJECT


// START FORMATER
export const numberFormatter = ({ stateValue, stateMinFraction = 0, stateMaxFraction = 0, statePrefix = '', stateDefaultIfError = '' }) => {
    const res = new Intl.NumberFormat('en-US', {
        // style: 'currency',
        // currency: '???',
        maximumFractionDigits: stateMaxFraction,
        minimumFractionDigits: stateMinFraction,
    })
        .format(stateValue)
        // if the price begins with digit, place the space after the digits
        .replace(/^([\d,.]+)/, '$1 ')
        // if the price ends with digit, place the space before the digit
        .replace(/([\d,.]+)$/, ' $1');

    return statePrefix + '' + (res === isNaN ? stateDefaultIfError : res);
};

export const numberWithComma = (stateValue = '') => {
    return String(stateValue).replace(/\D/g, "").replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};
// END FORMATER


// START SORT
export const sortTable = (stateElement, stateSort) => {
    var table, robs, switching, i, x, y, shouldSwitch;
    // table = document.getElementById("myTable");

    table = stateElement;

    switching = true;
    /*Make a loop that will continue until
    no switching has been done:*/
    while (switching) {
        //start by saying: no switching is done:
        switching = false;
        robs = table.rows;
        /*Loop through all table robs (except the
        first, which contains table headers):*/
        for (i = 1; i < (robs.length - 1); i++) {
            //start by saying there should be no switching:
            shouldSwitch = false;
            /*Get the two elements you want to compare,
            one from current row and one from the next:*/
            x = robs[i].getElementsByTagName("TD")[0];
            y = robs[i + 1].getElementsByTagName("TD")[0];
            //check if the two robs should switch place:

            if (stateSort === 'asc') {
                // if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                if (Number(x.innerHTML) < Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            } else {
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        if (shouldSwitch) {
            /*If a switch has been marked, make the switch
            and mark that a switch has been done:*/
            robs[i].parentNode.insertBefore(robs[i + 1], robs[i]);
            switching = true;
        }
    }
}

export const dynamicSort = (property) => {
    let sortOrder = 1;
    let sortSlug = property.slug;

    let aa = '';
    let bb = '';

    if (property && property.slug && property.slug[0] === "-") {
        sortOrder = -1;
        sortSlug = property.slug.substring(1);
    }

    return function (a, b) {
        if (property.type === utils.sortType.integer.slug) {
            aa = a[sortSlug] ? Number(a[sortSlug]) : a[sortSlug];
            bb = b[sortSlug] ? Number(b[sortSlug]) : b[sortSlug];
        } else if (property.type === utils.sortType.float.slug) {
            aa = a[sortSlug] ? parseFloat(a[sortSlug]) : a[sortSlug];
            bb = b[sortSlug] ? parseFloat(b[sortSlug]) : b[sortSlug];
        }
        // else if (property.type === utils.sortType.date.slug) {
        //     aa = a[sortSlug] ? sqlDateToIsoDate(a[sortSlug]) : a[sortSlug];
        //     bb = b[sortSlug] ? sqlDateToIsoDate(b[sortSlug]) : b[sortSlug];
        // }
        else {
            aa = a[sortSlug] ? a[sortSlug].toLowerCase() : a[sortSlug];
            bb = b[sortSlug] ? b[sortSlug].toLowerCase() : b[sortSlug];
        }

        if (property && sortSlug) {
            if (sortOrder === 1) {
                if (aa === null) { return 1; }
                if (bb === null) { return -1; }
                if (aa === bb) { return 0; }
                return aa < bb ? -1 : 1;
            } else {
                if (aa === null) { return 1; }
                if (bb === null) { return -1; }
                if (aa === bb) { return 0; }
                return aa < bb ? 1 : -1;
            }
        } else {
            return aa;
        }
    }
}
// END SORT


// START UTILS
export const isHasMore = (dataLength, dataAll) => {
    let isHasmore = false;

    if (Number(dataLength) < Number(dataAll)) {
        isHasmore = true;
    }

    return isHasmore;
}

export const defaultTab = (stateDefaultTab) => {
    try {
        return Object.keys(stateDefaultTab.tabs).length > 0 ? stateDefaultTab.tabs.one.flag : '';
    } catch (error) {
        return ''
    }
}

export const genderMaleFemaleUngenderd = (stateGender, stateDefaultValue = constDefaultValue) => {
    try {
        let res = '-';

        if (String(stateGender) === '1') {
            res = 'Pria';
        } else if (String(stateGender) === '0') {
            res = 'Wanita';
        } else {
            res = '-';
        }

        return res;
    } catch (error) {
        return stateDefaultValue;
    }
}

export const removeFromArrayByIndex = (stateIndex, stateArray) => {
    let res = [];

    if (Array.isArray(stateArray)) {
        for (let index = 0; index < stateArray.length; index++) {
            if (index !== Number(stateIndex)) {
                res.push(stateArray[index]);
            }
        }
    }

    return res;
}

export const changeValueOfArray = (stateArray, stateKey, stateProperty, stateNewValue) => {
    const values = [...stateArray];

    values[stateKey][stateProperty] = stateNewValue;

    return values;
}

export const changeValueOfArrayImmutable = (stateArray, stateKey, stateProperty, stateNewValue) => {
    const newValue = stateArray.map((obj, idx) => {
        if (Number(idx) === Number(stateKey)) {
            return { ...obj, [stateProperty]: stateNewValue };
        }

        return obj;
    })

    return newValue;
}

export const changeValueOfArrayObjectImmutable = (stateArray, statePropIndicator, statePropIndicatorValue, statePropChange, stateNewValue) => {
    const newValue = stateArray.map((obj, idx) => {
        if (obj[statePropIndicator] === statePropIndicatorValue) {
            return { ...obj, [statePropChange]: stateNewValue };
        }

        return obj;
    })

    return newValue;
}

export const isEmptyArrayObject = (stateArray, stateProperty) => {
    if (Array.isArray(stateArray) && stateArray.length > 0) {
        for (let index = 0; index < stateArray.length; index++) {
            if (!stateArray[index][stateProperty]) {
                return true;
            }
        }
    }

    return false;
}

export const isDuplicateValueInArrayObject = (stateArray, stateProp, stateBooleanIsCaseSensitive = false, stateCanEmptyValue = false) => {

    const normalizeArray = Array.isArray(stateArray) ? stateArray : [];

    const nonEmptyValue = normalizeArray.filter(val => val[stateProp] !== '' && val[stateProp] !== null);

    const resArray = stateCanEmptyValue ? nonEmptyValue : stateArray;

    const unique = [...new Set(resArray.map(propYoureChecking => stateBooleanIsCaseSensitive ? propYoureChecking[stateProp] : regexNoSpace(String(propYoureChecking[stateProp]).toLowerCase())))];

    if (unique.length < resArray.length) {
        return true;
    } else {
        return false;
    }
}

export const arrayObjectToStringWithDivider = (stateArray, stateProp, stateDivider = ', ', stateDefaultValue = constDefaultValue) => {
    try {
        return (Array.isArray(stateArray) && (stateArray.length > 0)) ? Object.values(stateArray).map(key => key[stateProp]).join(stateDivider) : stateDefaultValue;
    } catch (error) {
        return stateDefaultValue;
    }
}

export const safeValue = (stateValue, stateIfNull) => {
    return stateValue ? stateValue : stateIfNull;
}

export const stringDivider = (stateString, stateArrayPosition, stateDivider, stateDefaultValue = constDefaultValue) => {
    try {
        let res = '';

        if (stateString.length >= stateArrayPosition.length) {
            for (let x = 0; x < stateString.length; x++) {
                res += stateString[x];

                for (let y = 0; y < stateArrayPosition.length; y++) {
                    res += (x === (stateArrayPosition[y] - 1) ? stateDivider : '');
                }
            }

            return res;
        }

        return stateDefaultValue;
    } catch (error) {
        return stateDefaultValue;
    }
}

export const fnBreakPointItems = (stateIsShowDetails) => {
    return [
        { minWidth: 'xl', cols: stateIsShowDetails ? 4 : 5, spacing: 'md' },
        { minWidth: 'lg', cols: stateIsShowDetails ? 3 : 4, spacing: 'md' },
        { minWidth: 'md', cols: stateIsShowDetails ? 2 : 3, spacing: 'md' },
        { minWidth: 'sm', cols: 2, spacing: 'md' },
        { minWidth: 'xs', cols: 2, spacing: 'md' }
    ]
}
// END UTILS