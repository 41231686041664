import React from 'react';

import { ActionIcon, Text, MediaQuery, TextInput, useMantineTheme, Group, Box, ScrollArea, Menu, NavLink, Paper } from '@mantine/core';
import { IconSettings, IconUser, IconAlignLeft, IconSearch, IconPhoto, IconMessageCircle, IconTrash, IconArrowsLeftRight, IconPlus, IconFolderPlus, IconFolder, IconFile, IconFolders, IconUsers, IconClockHour4, IconStar, IconCloud, IconAdjustmentsHorizontal, IconX } from '@tabler/icons-react';

import { utils, brand } from '../utils/globalVariables';

const SearchHeaderComponent = () => {

    const theme = useMantineTheme();

    const [data, setData] = React.useState({
        value: ''
    })

    return (
        <React.Fragment>
            <TextInput
                placeholder="Search in Drive"
                radius="lg"
                size='md'
                value={data.value}
                onChange={(e) => setData(d => ({ ...d, value: e.target.value }))}

                icon={
                    <IconSearch
                        size="1rem"
                        stroke={1.5}
                        color={theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.dark[0]}
                    />
                }

                styles={(tema) => ({
                    root: {
                        width: '100%',
                    },
                    input: {
                        borderColor: tema.colorScheme === 'light' ? '#EDF2FC' : tema.colors.dark[5],
                        borderWidth: '1px',
                        borderStyle: 'solid',

                        backgroundColor: tema.colorScheme === 'light' ? '#EDF2FC' : tema.colors.dark[6],
                        transition: '.25s',
                        paddingRight: data.value ? '5.25rem' : '2.625rem',
                        '&::placeholder': {
                            color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.dark[0],
                            opacity: 1, /* Firefox */
                        },
                        '&::-ms-input-placeholder': { /* Edge 12-18 */
                            color: tema.colorScheme === 'light' ? tema.colors.gray[7] : tema.colors.dark[0],
                        },
                        '&:focus': {
                            backgroundColor: tema.colorScheme === 'light' ? '#fff' : tema.colors.dark[5],
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: tema.colorScheme === 'light' ? tema.colors.gray[3] : tema.colors.blue[8],
                        }
                    },
                    rightSection: {
                        width: data.value ? '5.25rem' : '2.625rem'
                    }
                })}

                rightSection={
                    <React.Fragment>
                        {data.value &&
                            <ActionIcon
                                variant='subtle'
                                radius='xl'
                                size='lg'
                                mr='.25rem'
                                sx={{
                                    border: 0,
                                    color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.dark[1],
                                    backgroundColor: 'transparent',
                                    transition: '.25s',
                                    '&:hover': {
                                        backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[4],
                                    }
                                }}
                                title={'Clear'}
                                onClick={() => setData(d => ({ ...d, value: '' }))}
                            >
                                <IconX
                                    size={22}
                                    strokeWidth={1.5}
                                />
                            </ActionIcon>
                        }

                        <ActionIcon
                            variant='subtle'
                            radius='xl'
                            size='lg'
                            sx={{
                                border: 0,
                                color: theme.colorScheme === 'light' ? theme.colors.gray[9] : theme.colors.dark[1],
                                backgroundColor: 'transparent',
                                transition: '.25s',
                                '&:hover': {
                                    backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[4],
                                }
                            }}
                            title={'Filter'}
                        >
                            <IconAdjustmentsHorizontal
                                size={22}
                                strokeWidth={1.5}
                            />
                        </ActionIcon>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    )
}

export default SearchHeaderComponent;